import React from "react";
import {Button, Paper, Box} from '@mui/material';
import DLG_NavBar from "./DLG_NavBar";
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {darken, lighten} from '@mui/material/styles';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export default function MainSchedule() {
    const [schedule, setSchedule] = React.useState([]);
    React.useEffect(() => {
        fetch('/api/schedule').then(res => res.json()).then(data => {
            setSchedule(data)
        });
    }, []);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', type: 'number', width: 25},
        {
            field: 'type',
            headerName: 'event',
            width: 230,
            editable: false,
        },
{
  field: 'send_date',
  headerName: 'Date sent',
  type: 'dateTime',
  width: 190,
  editable: false,
  valueGetter: (params) => new Date(params.value),
  valueFormatter: (params) => {
    const date = params.value;
    const formattedDate = date.toLocaleString(); // Customize the date formatting as per your requirements
    return formattedDate;
  },
},


        {
            field: 'delivered',
            headerName: 'Delivered',
            width: 100,
            editable: false,
        },
        {
            field: 'confirmation_date',
            headerName: 'Confirmed',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Delivered to',
            width: 200,
            editable: false,
        },
        {
            field: 'name_client',
            headerName: 'Client',
            width: 200,
            editable: false,
        },
        {
            field: 'source',
            headerName: 'Send by',
            width: 200,
            editable: false,
        },
        {
            field: 'comment',
            headerName: 'Comment',
            width: 400,
            editable: false,
        },
    ];

    return (
        <Box style={{height: 1000}} mb={-7}
             sx={{
                 '& .super-app-theme--reminder': {
                     bgcolor: (theme) =>
                         getBackgroundColor(theme.palette.reminder.main, theme.palette.mode),
                     '&:hover': {
                         bgcolor: (theme) =>
                             getHoverBackgroundColor(
                                 theme.palette.reminder.main,
                                 theme.palette.mode,
                             ),
                     },
                 },
                 '& .super-app-theme--extend_request': {
                     bgcolor: (theme) =>
                         getBackgroundColor(theme.palette.extend_request.main, theme.palette.mode),
                     '&:hover': {
                         bgcolor: (theme) =>
                             getHoverBackgroundColor(
                                 theme.palette.extend_request.main,
                                 theme.palette.mode,
                             ),
                     },
                 },
                 '& .super-app-theme--close_request': {
                     bgcolor: (theme) =>
                         getBackgroundColor(theme.palette.close_request.main, theme.palette.mode),
                     '&:hover': {
                         bgcolor: (theme) =>
                             getHoverBackgroundColor(
                                 theme.palette.close_request.main,
                                 theme.palette.mode,
                             ),
                     },
                 },
                 '& .super-app-theme--extend_confirmation': {
                     bgcolor: (theme) =>
                         getBackgroundColor(theme.palette.extend_confirmation.main, theme.palette.mode),
                     '&:hover': {
                         bgcolor: (theme) =>
                             getHoverBackgroundColor(
                                 theme.palette.extend_confirmation.main,
                                 theme.palette.mode,
                             ),
                     },
                 },
                 '& .super-app-theme--close_confirmation': {
                     bgcolor: (theme) =>
                         getBackgroundColor(theme.palette.close_confirmation.main, theme.palette.mode),
                     '&:hover': {
                         bgcolor: (theme) =>
                             getHoverBackgroundColor(
                                 theme.palette.close_confirmation.main,
                                 theme.palette.mode,
                             ),
                     },
                 },

             }}
        >
            <DataGrid
                rows={schedule}
                columns={columns}
                disableSelectionOnClick
                initialState={{
                    sorting: {sortModel: [{field: "id", sort: "desc"}]}
                }}
                getRowId={(row) => row.id}
                getRowClassName={(cellValues) => `super-app-theme--${cellValues.row.type}`}
            />
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={10}>
                <DLG_NavBar highlight={"Schedule"}/>
            </Paper>
        </Box>
    );
}