import {Divider,FormControl, List, ListItem, ListItemText, Button, Checkbox, Select, MenuItem, Input, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, FormControlLabel,NativeSelect, Stack, TextField, DialogActions, Tooltip, Paper, Box, Typography, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {darken, lighten } from '@mui/material/styles';
import React from "react";

export default function DLG_JobList(props) {
    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const handleClose = () => {
        props.dlgSetOpen(false)
    }
    const weeksLength=(params)=>{
        let weeks_count = Math.round((new Date("20"+params.end) - new Date("20"+params.start)) / (1000 * 60 * 60 * 24 * 7))
        console.log(new Date("20"+params.end))
        return(
            weeks_count+" weeks"
        )
    }
    return(
        <Dialog open={props.dlgOpen} onClose={handleClose} fullWidth sx={{
                        '& .super-app-theme--finished': {
                        bgcolor: (theme) =>
                            getBackgroundColor("#929b93", theme.palette.mode),
                        },
                        '& .super-app-theme--current': {
                            bgcolor: (theme) =>
                                getBackgroundColor("#67ff9b", theme.palette.mode),
                        },
                        '& .super-app-theme--overdue': {
                            bgcolor: (theme) =>
                                getBackgroundColor("#ff4b52", theme.palette.mode),
                        },
                    }}>
            <DialogContent>
                {Object.keys(props.joblist).map((item) =>
                <div key={props.joblist[item].id} >
                    <Grid  container className={`super-app-theme--${props.joblist[item].status}`} columnSpacing={1}>
                        <Grid item xs={1}>
                            <Typography align="center" variant="caption">{props.joblist[item].invoice_hire}: </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography align="center" variant="caption">S: {props.joblist[item].start}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography align="center" variant="caption">F: {props.joblist[item].end}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography align="center" variant="caption">C: {props.joblist[item].finished}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography align="center" variant="caption">{weeksLength(props.joblist[item])}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {props.joblist[item].amountpaid_job ?
                                    <Typography align="center"
                                                variant="caption">{props.joblist[item].paid_job}:&nbsp;£{props.joblist[item].amountpaid_job}</Typography> :
                                    ""
                            }
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={11}>
                        <Typography align="center" variant="caption">{props.joblist[item].address_hire}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>

                        <Grid item xs={8}>
                        <Typography align="center" variant="caption">{props.joblist[item].note}</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </div>
                )}
            </DialogContent>
        </Dialog>
    )
}