import {Button} from '@mui/material';
import React from "react";

export default function DLG_DBChecker() {


    const database = (event) => {
        fetch('/api/db/checkDB').then(res => res.json()).then(data => {
            console.log(data)
        })
    }



    return(
            <div>
                <Button variant="contained" value="backup" onClick={database}>Check DB</Button>
            </div>
    )
}