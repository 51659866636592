import React, {useState} from 'react';
import {IconButton, Stack, Tooltip, Dialog} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ClearSchedule = (props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogOpen = (event) => {
        event.preventDefault(); // Prevent the default link behavior
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    if (props.type) {
        return (
            <Stack direction="row">
                <style>
                    {`
            .custom-link {
              text-decoration: none;
              color: inherit;
            }
            .custom-link:hover {
              color: aqua;
            }
          `}
                </style>
                <Tooltip title="Clear schedule">
                    <IconButton
                        size="small"
                        variant="contained"
                        color="default"
                        onClick={() => {
                            props.onJobChange("clear_schedule", props.hashcode);
                        }}
                    >
                        <HighlightOffIcon/>
                    </IconButton>
                </Tooltip>
                <Stack>
                    <div>
                        <a
                            href={"/client/" + props.hashcode}
                            className="custom-link"
                            onClick={handleDialogOpen}
                        >
                            {props.type}
                        </a>
                    </div>
                    <div>{props.date}</div>
                </Stack>

                {/* Dialog Component */}
                <Dialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    maxWidth="lg"
                    fullWidth
                    sx={{
                        "& .MuiDialog-paper": {
                            padding: "40px" // Adjust the padding value as needed
                        }
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={handleDialogClose}
                        style={{position: 'absolute', right: '8px', top: '8px'}}
                    >
                        <HighlightOffIcon/>
                    </IconButton>
                    <iframe
                        src={"/client/" + props.hashcode}
                        title="Client"
                        style={{
                            width: '100%',
                            height: '80vh',
                            border: 'none'
                        }}
                    />
                </Dialog>
            </Stack>
        );
    } else {
        return <div/>;
    }
};

export default ClearSchedule;
