import {createTheme} from "@mui/material/styles";

export const createCustomTheme =  createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#363537',
            dark: '#2d3b4c',
        },
        secondary: {
            main: '#b76615',
        },
        warning: {
            main: '#943215',
        },
        error: {
            main: '#ff3b80',
        },
        background: {
            default: '#a9a9a9',
            paper: '#424242',
        },
        finished: {
            main: '#ffffff',
            contrastText: '#353535',
        },
        current: {
            main: '#00a930',
        },
        overdue: {
            main: '#a9002e',
        },
        ending: {
            main: '#97a951',
        },
        confirmed: {
            main: '#a800a9',
        },
        waiting: {
            main: '#0030a9',
        },
        extend_request: {
            main: '#47a900',
            dark: '#4c0008',
        },
        close_request: {
            main: '#a90049',
            dark: '#4c0008',
        },
        extend_confirmation: {
            main: '#87a971',
            dark: '#4c0008',
        },
        close_confirmation: {
            main: '#a9747a',
            dark: '#4c0008',
        },
        reminder: {
            main: '#08a9a7',
            dark: '#2d3b4c',
        },
        paid: {
            main: '#08a9a7',
            dark: '#2d3b4c',
        },
        active: {
            main: '#08a9a7',
            dark: '#2d3b4c',
        },
        job_normal: {
            main: '#425659',
            dark: '#222e33',
        },
        job_update: {
            main: '#224c53',
            dark: '#222e33',
        },
        job_extended: {
            main: '#238d9d',
            dark: '#2d3b4c',
        },
        job_closed: {
            main: '#238d9d',
            dark: '#2d3b4c',
        },
        job_reminder: {
            main: '#238d9d',
            dark: '#2d3b4c',
        },
        clear_schedule: {
            main: '#238d9d',
            dark: '#2d3b4c',
        }
    },
    typography: {
        fontFamily: 'Montserrat',
        fontWeightBold: 1000,
        fontWeightMedium: 600,
        fontSize: 11,
    },

});