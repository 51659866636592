import {
    List,
    ListItem,
    ListItemText,
    Button,
    Checkbox,
    Switch,
    MenuItem,
    Input,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    NativeSelect,
    Stack,
    TextField,
    DialogActions,
    Tooltip,
    Paper,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from "react";

export default function DLG_AddClient(props) {


    const handleClose = () => {
        props.dlgSetOpen(false)
    }
    const handleOpen = () => {
    }
    const handleUpdate = async (event) => {
        event.preventDefault();
        let data = {
            'client_id': event.target.Client_Id.value,
            'client_name': event.target.Client_Name.value,
            'client_address': event.target.Client_Address.value,
            'client_description': event.target.Client_Info.value,
            'emails': event.target.Client_Emails.value,
            'phones': event.target.Client_Phones.value,
        }
        fetch('/api/clients/update', {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then(data => {
            handleClose()
        });
    }

    const splitList = (item) => {
        if (item == undefined || item == 'None' || item == "") {
            return []
        }
        var arr = []
        arr = item.split(";");
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                arr.splice(i, 1);
            }
        }
        return (arr)
    }

    const FormTxtItem = (item) => {
        return (
            <TextField
                key={item.key}
                autoFocus
                multiline
                margin="dense"
                id={item.formname}
                label={item.formname}
                inputRef={nameRef}
                fullWidth
                variant="standard"
                defaultValue={item.formvalue}
            />
        )
    }

    const emailRef = React.useRef();
    const addressRef = React.useRef();
    const infoRef = React.useRef();
    const phoneRef = React.useRef();
    const nameRef = React.useRef();


    const updateDialog = () => {
    }


    const nothing = () => {
    }

    return (
        <div>
            <Dialog open={props.dlgOpen} onClose={handleClose}>
                <form id="submit_job" onSubmit={handleUpdate}>
                    <DialogTitle>Edit client: {props.client_details.name_client}</DialogTitle>
                    <DialogContent>
                        <TextField
                            key="client_01"
                            autoFocus
                            multiline
                            margin="dense"
                            id="Client_Name"
                            label="Client_Name"
                            inputRef={nameRef}
                            fullWidth
                            variant="standard"
                            defaultValue={props.client_details.name_client}
                        />
                        <TextField
                            key="client_02"
                            autoFocus
                            multiline
                            margin="dense"
                            id="Client_Address"
                            label="Client_Address"
                            inputRef={addressRef}
                            fullWidth
                            variant="standard"
                            defaultValue={props.client_details.address_client}
                        />
                        <TextField
                            key="client_03"
                            autoFocus
                            multiline
                            margin="dense"
                            id="Client_Info"
                            label="Client_Info"
                            inputRef={infoRef}
                            fullWidth
                            variant="standard"
                            defaultValue={props.client_details.description_client}
                        />
                        <input key="client_04" type="hidden" name="Client_Id"
                               value={props.client_details.id_client}/>
                        <input key="client_05" type="hidden" name="Client_Emails"
                               value={props.client_details.emails_client}/>
                        <input key="client_06" type="hidden" name="Client_Phones"
                               value={props.client_details.phones_client}/>
                        <Typography>Subscribed:</Typography><Switch defaultChecked/>
                        <Stack direction='row' mt={3}>
                            <Stack>
                                <Stack direction='row'>
                                    <TextField key="phone_key" inputRef={emailRef} size="small" type="email"
                                               label="Add email"/><Button
                                    onClick={() => props.fn_addItem(emailRef.current.value, "emails_client")}><AddCircleIcon/></Button>
                                </Stack>
                                <Stack direction='row'>
                                    <List dense>
                                        {splitList(props.client_details.emails_client).map((value, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton key={"email_" + index} edge="end"
                                                                    aria-label="delete"
                                                                    onClick={() => props.fn_removeItem(index, "emails_client")}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={value}
                                                    />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack direction='row'>
                                    <TextField key="email_key" inputRef={phoneRef} size="small" type="tel"
                                               label="Add phone number"/><Button
                                    onClick={() => props.fn_addItem(phoneRef.current.value, "phones_client")}><AddCircleIcon/></Button>
                                </Stack>
                                <Stack direction='row'>
                                    <List dense>
                                        {splitList(props.client_details.phones_client).map((value, index) => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton key={"phone_" + index} edge="end"
                                                                    aria-label="delete"
                                                                    onClick={() => props.fn_removeItem(index, "phones_client")}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }>
                                                    <ListItemText
                                                        primary={value}
                                                    />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Stack>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel Changes</Button>
                        <Button type="submit">Confirm</Button>
                        {props.client_details.emails_client ?
                            <Button type="submit">Confirm and Email</Button> : ""
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}