import {Fab ,Button, ButtonGroup, Divider, Paper, Box, Grid, Typography, Stack, TextField} from '@mui/material';
import DLG_NavBar from "./DLG_NavBar";
import React from "react";
import DLG_Alert_General from "./DLG_Alert_General";
import DLG_AddClient from "./DLG_AddClient";
import DLG_AddJob from "./DLG_AddJob";
import DLG_JobList from "./DLG_JobList";
import DLG_MergeJobs from "./DLG_MergeJobs";

import DG_ClientList from "./DG_ClientList";
import AddIcon from '@mui/icons-material/Add';

function split_to_list(items){
    const name_list = items.split(";").map((item) =>
    <Typography align="left" variant="body1">{item}</Typography>
    )
    return (
       name_list
    )
};
    const splitList = (item) =>{
        if (item==undefined || item == 'None'){
            return []
        }
        var split = []
        split = item.split(";");
        return (split)
    }
export default function MainClients() {
    const [clients, setClients] = React.useState([]);
    const [clientDetails, setClientDetails] = React.useState({
        "id_client":null,
        "name_client":"",
        "address_client":"",
        "description_client":"",
        "phones_client":"",
        "emails_client":""
    });
    const [editOpen, setEditOpen] = React.useState( false);
    const [addJobOpen, setAddJobOpen] = React.useState( false);
    const [jobListOpen, setJobListOpen] = React.useState( false);
    const [addresses, setAddresses] = React.useState([]);
    const [jobList, setJobList] = React.useState([])
    const [jobDetail, setJobDetail] = React.useState({});
    const [listItem, setListItem] = React.useState(({"index":-1 , "option":""}))
    const [lastInvoice, setLastInvoice] = React.useState(null)
    const [alertOpen, setAlertOpen] = React.useState( false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [recordModified, setRecordModified] = React.useState( false)
    const [mergeClient, setMergeClient] = React.useState( []);
    const [mergeClientsOpen, setMergeClientsOpen] = React.useState( false);

    const fabStyle = {
      position: 'absolute',
      bottom: 56,
     left: 36,
    };
    const initClient = ({
        "id_client":null,
        "name_client":"",
        "address_client":"",
        "description_client":"",
        "phones_client":"",
        "emails_client":""
    });
    const addItem = (value, option) => {

        if (option === "emails_client"){
            let newlist = (clientDetails.emails_client+";"+value)
            setClientDetails({ ...clientDetails, "emails_client": newlist});
        } else if(option === "phones_client"){
            let newlist = (clientDetails.phones_client+";"+value)
            setClientDetails({ ...clientDetails, "phones_client": newlist});
        }
    }

    const GetClientJobs =(params) => {
        console.log(params.row.id_client)
        fetch('/api/clients/getjobs/'+params.row.id_client).then(res => res.json()).then(data => {
            setJobList(data)
            setJobListOpen(true)
        });
    }

    const GetInvoice = () => {
        fetch('/api/config/lastinvoice').then(res => res.json()).then(data => {
                setLastInvoice(data)
                setJobDetail({jobDetail, ...jobDetail, invoice_hire:data})
            });
    }
    const removeItem = (index, option) => {
        if (option === "emails_client"){
            const splitemail = splitList(clientDetails.emails_client);
            splitemail.splice(index,1)
            setClientDetails({ ...clientDetails, "emails_client": splitemail.join(";")});
        } else if(option === "phones_client"){
            const splitemail = splitList(clientDetails.phones_client);
            splitemail.splice(index,1)
            setClientDetails({ ...clientDetails, "phones_client": splitemail.join(";")});
        }
    }

    const resetState = () => {
        setClientDetails({clientDetails, ...initClient})
    }
    const EditClient = (params) => {
        fetch('/api/clients/get/'+params.id).then(res => res.json()).then(data => {
            setClientDetails(data);
            setEditOpen(true);
        });
    }
    const AddClient = () => {
        if (editOpen === false){
            resetState();
        }
        setEditOpen(true);
    }

    const MergeClients = (params) => {
        setMergeClient([params.id_client, params.name_client])
        setMergeClientsOpen(true);
    }

    const UpdateClients = (params) => {
        console.log("updating")
    }

    const AddJob = (params) =>{
        setJobDetail({jobDetail, ...params.name_client})
        setAddJobOpen(true);
    }

    React.useEffect(() => {
        fetch('/api/config/addresses').then(res => res.json()).then(data => {
            setAddresses(data)
        });
        fetch('/api/clients/get').then(res => res.json()).then(data => {
            setClients(data)
            setRecordModified( false)
        });
    }, [mergeClientsOpen, editOpen, recordModified]);

    return (
        <Box mb={-7}>
            <Fab variant="extended" size="small" color="primary" aria-label="add" sx={fabStyle} onClick={()=>AddClient()}>
              <AddIcon sx={{ mr: 1 }} />
              Add Client
            </Fab>
            <DG_ClientList gridItems={clients} recordModified={setRecordModified} fn_updateGrid={(client_id) => UpdateClients(client_id)} fn_add={(client_id) => AddJob(client_id)} fn_edit={EditClient} fn_info={(client_id) => GetClientJobs(client_id)} fn_merge={(client_row) => MergeClients(client_row)} />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={10}>
                <DLG_NavBar highlight={"Clients"}/>
                <DLG_AddClient dlgOpen={editOpen} dlgSetOpen={setEditOpen} client_details={clientDetails} setclient_details={setClientDetails} clients={clients} list_item={setListItem} fn_removeItem={(index,option) => removeItem(index,option)} fn_addItem={(value,option) => addItem(value,option)}/>
                <DLG_AddJob dlgOpen={addJobOpen} dlgSetOpen={setAddJobOpen} client_details={clientDetails} setclient_details={setClientDetails} clients={clients} addresses={addresses} job_details={jobDetail} fn_getinvoice={GetInvoice}/>
                <DLG_Alert_General open={alertOpen} message={alertMessage}/>
                <DLG_JobList dlgOpen={jobListOpen} dlgSetOpen={setJobListOpen} joblist={jobList} client={mergeClient}/>
                <DLG_MergeJobs dlgOpen={mergeClientsOpen} dlgSetOpen={setMergeClientsOpen} clientlist={clients} client={mergeClient}/>
            </Paper>
        </Box>
    );
}




