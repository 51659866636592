import {Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField, Tooltip, IconButton} from '@mui/material';
import React from "react";

import MoreTimeIcon from '@mui/icons-material/MoreTime';


export default function DLG_ButtonExtendHire(props) {
     const [dialogopen, setDialogOpen] = React.useState(false);

      const handleDialogOpen = () => {
            setDialogOpen(true);
      };

    function JobChange(event){
        event.preventDefault()
        console.log(event.target.extend_by.value)
        var url = '/api/job/extend_confirmation/'+props.hashcode+'/'+event.target.extend_by.value;
        handleDialogClose()
        props.fn_alert("Time extension")
        fetch(url).then(response => response.json()).then(data => {
                console.log("extension sent")
                window.location.reload()
            }
            );
    }
    function keyPress(e){
      if(e.keyCode == 13){
         console.log('value', e.target.value);
         var url = '/api/job/extend_confirmation/'+props.hashcode+'/'+e.target.value;
        fetch(url).then(response => response.json()).then(data =>
            handleDialogClose());
      }
   }
    const handleDialogClose = (event, reason) => {if (reason !='backdropClick'){setDialogOpen(false)}};
return (
      <Tooltip title="Extend hire time">
      <IconButton
      size={'small'}
      variant="contained"
      color="current"
      onClick={handleDialogOpen}
        >
        <MoreTimeIcon/>
                  <Dialog
                open={dialogopen}
                onClose={handleDialogClose}
              >
                      <form onSubmit={JobChange}>
                <DialogTitle>Extend by</DialogTitle>
                <DialogContent>
                    <Box mt={1
                    }><TextField type="number" label="Amount of weeks" id="extend_by" defaultValue={1} onKeyDown={keyPress}/></Box>
                </DialogContent>
                <DialogActions>
                  <Button type="submit">Confirm Changes</Button>

                </DialogActions></form>
              </Dialog>
        </IconButton>
      </Tooltip>
    )
}