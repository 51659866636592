import {Paper, Box, Grid, Button, Card, Stack, CardActions} from '@mui/material';
import React from "react";

import {saveAs} from "file-saver";

import DLG_NavBar from "./DLG_NavBar";
import CalcReport from "./Controls/CTRL_CalcReport";
import ScaffSegment from "./Controls/CTRL_CalcCard";
import CalcSummary from "./Controls/CTRL_CalcSummary";
import axios from "axios";
import fileDownload from "js-file-download";
import QuoteSelector from "./DLG_QuoteSelector";


export default function MainCalc() {
    const [template, setTemplate] = React.useState({
            'scafftype': {"name":"Scaffolding type", "result":null, "calc":"list","menulist":
                [
                {'option':'ID',"name":"Independent","price":0},
                {'option':'PL',"name":"Putlog","price":1},
                {'option':'LS',"name":"Lift Shaft","price":2},
                {'option':'CS',"name":"Chimney stack","price":3},
                {'option':'TW',"name":"Tower","price":4},
                {'option':'TM',"name":"Tower Mobile","price":5},
                {'option':'TF',"name":"Tower Free Standing","price":6},
                {'option':'BI',"name":"Birdcage Internal","price":7},
                {'option':'BE',"name":"Birdcage External","price":8},
                {'option':'TR',"name":"Temporary roofing","price":9}
            ], "optional":false, "active":true, "visible":true},
            'menuscaffarea': {"name":"purpose", "result":null, "calc":"area", "menulist":[
                {'option':'DS',"name":"Design","price":7},
                {'option':'VL',"name":"Very Light Duty","price":4},
                {'option':'LD',"name":"Light Duty","price":2},
                {'option':'GP',"name":"General Purpose","price":30},
                {'option':'HD',"name":"Heavy Duty","price":4}
            ], "optional":false, "active":true, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR']},
            'menuscaffvolume': {"name":"purpose", "result":null, "calc":"volume", "menulist":[
                {'option':'DS',"name":"Design","price":7},
                {'option':'VL',"name":"Very Light Duty","price":4},
                {'option':'LD',"name":"Light Duty","price":2},
                {'option':'GP',"name":"General Purpose","price":30},
                {'option':'HD',"name":"Heavy Duty","price":4}
            ], "optional":false, "active":true, "visible":true, 'valid':['BI','BE']},
            'menuscaffcladding': {"name":"Cladding", "result":null, "calc":"area","menulist":[
                {'option':'NT',"name":"Netting","price":4},
                {'option':'NF',"name":"Netting FR","price":2},
                {'option':'MN',"name":"Monoflex","price":5},
                {'option':'MF',"name":"Monoflex FR","price":4},
                {'option':'BG',"name":"Brickguards","price":1}
            ], "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR']},
            'fan':{"name":"Cantilever Fan", "result":null, "unit":"m", "calc":"len", "price":20, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'beam300':{"name":"Beamwork 300", "result":null, "unit":"m", "calc":"len", "price":22, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'beam450':{"name":"Beamwork 450", "result":null, "unit":"m", "calc":"len", "price":30, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'beam750':{"name":"Beamwork 750", "result":null, "unit":"m²", "calc":"len", "price":45, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'hoarding':{"name":"Hoarding", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'fencing':{"name":"Fencing", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'gantry':{"name":"Gantry", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'loading':{"name":"Loading Bay", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'lift':{"name":"Pavement Lift", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'handrails':{"name":"Handrails", "result":null, "unit":"m", "calc":"len", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'alarm':{"name":"Alarm", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'foams':{"name":"Foams", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'lights':{"name":"Lights", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'dboards':{"name":"Double Boards", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'safetyb':{"name":"Safety Baulks", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},
            'hoist':{"name":"Hoist Frame", "result":null, "calc":"len", "unit":"item", "price":50, "optional":true, "active":false, "visible":true, 'valid':['ID','PL','LS','CS','TW','TM','TF','TR','BI','BE']},

        })

    const [totalCalculation, setTotalTotalCalculation] = React.useState({
        'job_id':null,
        'priceList':null,
    })
    const [itemPriceList, setItemPriceList] = React.useState([])

    const [submitForm, setSubmitForm] = React.useState("")
    const [scaffItemList, setScaffItemList] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [addresses, setAddresses] = React.useState([]);
    const [selectedJob, setSelectedJob] = React.useState(null);
    const [scaffSegments, setScaffSegments] = React.useState([]);
    const [loadedData, setLoadedData] = React.useState([])

    React.useEffect(() => {

    }, [scaffItemList]);
    React.useEffect(() => {
        onHandleChange()
    }, []);
    React.useEffect(() => {

    }, [itemPriceList, totalCalculation]);

    const fn_item = () => {
        let tempList = [];
        for (var key in template) {
            if (template[key]) {
                tempList.push({
                    "name": template[key].name,
                    "result": template[key].result,
                    "price": template[key].price,
                    "active": template[key].active
                });
            }
        }
        return (tempList);
}



    const updateTotalCalculation = (jobID) => {
      setTotalTotalCalculation((prevState) => ({
        ...prevState,
        job_id: jobID,
        priceList: itemPriceList,
      }));
    };

    const addScaffSegment = () => {
        setScaffItemList((prevItems) => [...prevItems, fn_item()]);
    }

    async function fetchAndDownloadPDF() {
        const data = {totalCalculation};
        const response = await fetch("/api/quotation/pdf", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data.totalCalculation),
            });
        const { file_id } = await response.json();

        // Download the PDF file with the given file_id
        const fileResponse = await fetch('/static/downloads/'+file_id+'.pdf');
        const fileBlob = await fileResponse.blob();
        saveAs(fileBlob, "exported_data.pdf");
    }
    async function saveQuote() {
        const data = {totalCalculation};
        const response = await fetch("/api/quotation/store", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data.totalCalculation),
            });
        const { file_id } = await response.json();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitForm === "delete"){

        } else{

        }
        };

    const handleChange = (event) => {

    };

    const onHandleChange = (src, dest, index) => {

    };

    const onJobChange = (clientItem) => {
        // here we save stuff
        saveQuote()
    }

    const cardChange = (value, key) => {
        if (itemPriceList[key] === undefined) {
            setItemPriceList((prevList) => [...prevList.slice(0, key), value, ...prevList.slice(key + 1)]);
        } else {
            setItemPriceList((prevList) => {
                let updatedList = [...prevList];
                updatedList[key] = value;
                return updatedList;
            });
        }
    };

    const removeItemPriceListAtGivenIndex = (index) => {
        setItemPriceList((prevList) => {
            let updatedList = [...prevList];
            updatedList.splice(index, 1);
            return updatedList;
        });
        setScaffItemList((prevItems) => prevItems.filter((item, i) => i !== index));
    };


    const quotationSelect = (action, option) => {
        switch (action) {
            case "import":
                importQuotation(option)
                break;
            case "save":

                break;
            default:


                break;
        }
    }

    const updateScaffSegmentData = (index, data) => {
  // Logic to update the ScaffSegment instance using setState
        setScaffSegments((prevScaffSegments) => {
            const updatedScaffSegments = [...prevScaffSegments];
            updatedScaffSegments[index] = { ...updatedScaffSegments[index], ...data };
            return updatedScaffSegments;
          });
        };

    function  importQuotation (id_quote){
        fetch("/api/quotation/import/"+id_quote)
            .then((res) => res.json())
            .then((data) => {
                //this.setState((prevState) => ({
                //...prevState,
                //quotelist: data,
                setScaffItemList(Array.from({ length: data.quotelist.length }, () => fn_item()));
                setLoadedData(data.quotelist)
                data.quotelist.forEach((item, index) => {
                    updateScaffSegmentData(index, item);
                });
            })
                //}));
        }
    async function  saveQuotation (event){
        switch (event){
            case('save'):
                const response = await fetch("/api/quotation/store", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({'jobid':1, 'quote':itemPriceList}),
                    });

                break;
            default:
                break;
        }

    };

return (
        <Box>
                <form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={0}>
                        <Grid item xs={1}>
                                <Button variant="outlined" onClick={addScaffSegment}>Add segment</Button>
                        </Grid>
                        <Grid item xs={1}>
                                <Button variant="outlined" onClick={fetchAndDownloadPDF}>Download PDF</Button>
                        </Grid>
                        <Grid item xs={10}>
                            <QuoteSelector currentquote={totalCalculation} fn_update={quotationSelect}/>
                        </Grid>
                        <Grid item xs={3}>
                            <CalcSummary priceList = {itemPriceList} template={template}/>
                        </Grid>
                        <Grid item xs={9}>
                              <Box width="100%" sx={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              p: 1,
                              bgcolor: 'background.paper',
                              borderRadius: 1,
                              overflow: "hidden",
                              overflowX: "scroll",
                            }}>
                            {scaffItemList.map((item, index) => {
                                return(
                                    <Card  key={'card-'+String(index)} variant="outlined" sx={{minWidth: 350, maxWidth:450}}>
                                        <ScaffSegment
                                            itemtemplate={template}
                                            index={index}
                                            scaffquery = {scaffItemList}
                                            fn_update={(value, key) => cardChange(value, key)}
                                            scaffSegmentLoadedData={loadedData[index]}
                                        />
                                        <CardActions>
                                            <Button size="small">Reset</Button>
                                            <Button size="small" onClick={()=>removeItemPriceListAtGivenIndex(index)}>Remove</Button>
                                        </CardActions>
                                    </Card>
                                )
                            })}
                            </Box>
                        </Grid>
                    <Button variant="outlined" onClick={addScaffSegment}>Add segment</Button>
                </Grid>
                </form>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
                <DLG_NavBar highlight={"Calc"}/>
            </Paper>
        </Box>
    );
}