import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

class ClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      selectedClient: null,
    };
  }

  componentDidMount() {
    fetch('/api/clients/get')
      .then((res) => res.json())
      .then((data) => {
        this.setState((prevState) => ({
          ...prevState,
          clients: data,
        }));
        // Set the initial state of selectedClient based on the prop
        if (this.props.selectedClient) {
          this.setState({ selectedClient: this.props.selectedClient });
        }
      });
  }

  componentDidUpdate(prevProps) {
    // If the selectedClient prop has changed, update the state
    if (this.props.selectedClient !== prevProps.selectedClient) {
        this.setState({ selectedClient: this.props.selectedClient });
    }
  }

  render() {
    const ChangeClient = (event, clientdetails, reason) => {
      if (reason !== 'reset' && clientdetails) {
        this.props.fn_update({
          client_id: clientdetails.id_client,
          client_name: clientdetails.name_client,
        });
        this.setState({ selectedClient: clientdetails.id_client });
      }
    };

    if (!this.state.clients) {
      return <div>Loading...</div>;
    }

    const selectedClient = this.state.clients.find(
      (client) => client.id_client === this.props.selectedClient
    );

    return (
      <Box>
        <Autocomplete
          freeSolo
          id="client_name"
          onChange={(event, newValue, reason, details) =>
            ChangeClient(event, newValue, reason, details)
          }
          value={selectedClient || null}
          getOptionLabel={(option) => (option ? option.name_client : '')}
          options={this.state.clients}
          renderOption={(props, option, { selected }) => {
            const isSpecial = false;
            return (
              <li
                {...props}
                key={option.id_client}
                style={{
                  color: isSpecial ? 'red' : 'inherit',
                  backgroundColor: isSpecial ? 'lightgreen' : 'inherit',
                }}
              >
                {option.name_client}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Select Client"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </Box>
    );
  }
}
export default ClientList;
