import {
    IconButton,
    FormControl,
    MenuItem,
    Select,
    Stack,
    TextField, ToggleButton, Tooltip
} from "@mui/material";
import MailLockIcon from '@mui/icons-material/MailLock';
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

class JobMail extends React.Component {
     constructor(props) {
        super(props);
        this.state = {
            subscribed:false,
        };

      }

     componentDidMount() {
         this.setState({subscribed: this.props.mail_notification})
     }

    componentDidUpdate(prevProps, prevState, snapshot){
            if (prevProps != this.props) {
                this.setState({status: this.props.paid_job})
            }
        }
    render() {
        if (!this.props.mail_notification) {
            return <div>Loading...</div>;
        }
        const warningSwitch = () => {
            if (this.props.finished_job != 'None'){return false}
            if(Math.floor((new Date(this.props.stop_job) - new Date()) / (1000*60*60*24))<7){
                return true
            }
        }
        const subscribeUpdate = (event) => {
            let data = {
                'update_column': 'job',
                'id_job': this.props.id_job,
                'update': {
                    'mail_notification': (this.state.subscribed),
                }
            }
            if (event.currentTarget.value === 'True' ){
                this.setState({subscribed: 'False'})
                data.update.mail_notification=false
            } else {
                this.setState({subscribed: 'True'})
                data.update.mail_notification=true
            }
            fetch('/api/job/updatepart', {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(data),
                }).then(res => res.json()).then(data => {
                    this.props.fn_update()
                });
        }
        if (!this.props.id_job) {
            return <div>Loading...</div>;
        }
        return (
            <Stack direction='row'>
                <Stack width="50%">

                    <IconButton onClick={(event)=>subscribeUpdate(event, this.props.id_job)}
                        value={this.state.subscribed} color = {this.state.subscribed == 'True' ? "overdue": "primary"}><Tooltip title={this.state.subscribed == 'True' ? "This job is excluded from mailing": "Click to disable mailing notification for this particular job"}>
                <MailLockIcon/></Tooltip>
            </IconButton>
                    </Stack>
                <Stack width="50%">
                    <IconButton disabled>
                    {warningSwitch() ? <WarningAmberIcon color="error"/>:""}
                </IconButton></Stack>
            </Stack>
        );
    }
}

export default JobMail;