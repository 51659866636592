import React from "react";
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function DLG_Alert_General(props) {
  const { open, message, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const action = (
    <IconButton
      size="large"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={1000} // Updated to 2000 milliseconds (2 seconds)
      message={message}
      action={action}
      onClose={handleClose}
    />
  );
}