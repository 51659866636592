import {
    InputAdornment,
    Divider,
    ToggleButton,
    Switch,
    Autocomplete,
    Button,
    Checkbox,
    Select,
    MenuItem,
    OutlinedInput,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    FormControl,
    FormControlLabel,
    NativeSelect,
    Stack,
    TextField,
    DialogActions,
    Tooltip,
    Paper,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {format} from 'date-fns'
import React from "react";
import CalcItem from "./CTRL_CalcItem";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

class HireExtend extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: this.props.open,
            notes_job: "",
            stop_job: null,
            originalDate: null,
            weeksAdded: 0,
            weeksTypedIn: false
        }
    }

    componentDidMount() {

        let original_date = this.props.row.stop_job;
        //let original_date = new Date(this.props.stop_job).toLocaleDateString(); // Make a copy of the prop value
        this.setState({
            notes_job: this.props.row.notes_job,
            stop_job: original_date,
            originalDate: original_date
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check if the stop_job date changed and update weeksAdded if necessary
        console.log(this.state.originalDate)
        if (this.state.stop_job !== this.state.originalDate && prevState.stop_job !== this.state.stop_job) {
            this.setState({weeksAdded: Math.round((new Date(this.state.stop_job) - new Date(this.state.originalDate)) / 604800000)});
        }

        if (this.state.weeksTypedIn && prevState.weeksAdded !== this.state.weeksAdded) {
        //if (this.state.stop_job !== this.state.originalDate && prevState.weeksAdded !== this.state.weeksAdded) {
            console.log("changed weeks")
            const milliseconds = new Date(this.state.originalDate).getTime() + (604800000 * this.state.weeksAdded)
            this.setState({weeksTypedIn:false})
            const newDate = new Date(milliseconds);
            const year = newDate.getFullYear();
            const month = ("0" + (newDate.getMonth() + 1)).slice(-2); // Months are zero indexed so +1 and pad with 0 if needed
            const day = ("0" + newDate.getDate()).slice(-2); // pad with 0 if needed
            const formattedDate = `${year}-${month}-${day}`;
            this.setState({stop_job: formattedDate})
            //this.setState({originalDate: formattedDate})
        }

        // Check if the dialog open status changed and update dialogOpen if necessary
        if (this.props.open !== prevProps.open) {
            this.setState({dialogOpen: this.props.open});
        }

        // Check if the row prop changed and update local state accordingly
        if (this.props.row !== prevProps.row) {
            this.setState({
                notes_job: this.props.row.notes_job,
                stop_job: this.props.row.stop_job,
                originalDate: this.props.row.stop_job
            });
        }
    }

    render() {
        const handleDialogOpen = () => {
            this.setState({dialogOpen: true});

        };

        const checkOutdated = () => {
            const today = new Date();
            var dateString = this.state.stop_job;
            var date = new Date(dateString);

            if (today > date) {
                return <Typography pt={.8} style={{fontWeight: "bold", color: 'orange'}}>{dateString}</Typography>;
            } else if (today < date) {
                return <Typography pt={.8}>{dateString}</Typography>;
            } else {
                return <Typography pt={.8} style={{fontWeight: "bold", color: 'aqua'}}>{dateString}</Typography>;
            }
        }

        const JobChange = (event) => {
            event.preventDefault()
            if (event.nativeEvent.submitter.name != 'submit') {
                handleDialogClose()
                return
            }
            var assets = {
                'id_job': this.props.row.id_job,
                'hashcode_job': this.props.row.hashcode_job,
                'update_column': 'job',
                'update': {
                    'weeks_count': this.state.weeksAdded,
                    'stop_job': event.target.stop_job.value,
                    'notes_job': event.target.notes_job.value
                }
            }
            var url = '/api/job/extend_confirmation/' + this.props.row.hashcode + '/' + event.target.extend_by.value;


            fetch('/api/job/extend_confirmation', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",

                },
                body: JSON.stringify(assets),
            }).then(res => res.json()).then(data => {
                if (data) { // Change 'success' to the relevant key that indicates success in your API response
                    const updatedRow = {
                        ...this.props.row,
                        stop_job: this.state.stop_job,
                        notes_job: this.state.notes_job,
                    };
                    this.props.onUpdateRow(updatedRow);
                    handleDialogClose();
                } else {
                    console.error('Failed to extend job');
                }
            }).catch(error => console.error('Error:', error));
        }

        function keyPress(e) {
            //this.setState({weeksTypedIn: true})
            console.log(e.target.value)
            if (e.keyCode === 13) {
                console.log('value', e.target.value);
                var url = '/api/job/extend_confirmation/' + this.props.row.hashcode + '/' + e.target.value;
                fetch(url).then(response => response.json()).then(data =>
                    this.handleDialogClose());
            } else if (e.keyCode === 38) {
                //this.setState({weeksAdded:e.target.value})
                console.log("up")
            } else if (e.keyCode === 40) {
                console.log("down")
            }
        }

        const handleWeeksAdded = (event) => {
            console.log(event.target.value)
            this.setState({weeksTypedIn: true})
            this.setState({weeksAdded: event.target.value})
        }

        const handleDialogClose = (event, reason) => {
            if (reason != 'backdropClick') {
                this.setState({
                    dialogOpen: false,
                    notes_job: "",
                    originalDate: null,
                    stop_job: null,
                    weeksAdded: 0
                })
                this.props.onClose(false)
                console.log('closed')
            }
        };

        const handleTextChange = (event, key) => {
            this.setState({[key]: event.target.value})
            console.log(key, event.target.value)
        }

        return (
            <Stack direction='row'>
                <Tooltip title="Extend hire time">
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={handleDialogClose}
                    >
                        <form onSubmit={JobChange}>
                            <DialogTitle>Extend to</DialogTitle>
                            <DialogContent>
                                <Stack direction='row' mt={2} mb={2} spacing={1}>
                                    <TextField
                                        label="Amount of weeks"
                                        type="number"
                                        id="extend_by" value={this.state.weeksAdded}
                                        onKeyDown={keyPress}
                                        onChange={handleWeeksAdded}
                                    />
                                    <TextField
                                        type="date"
                                        label="Stop date"
                                        id="stop_job"
                                        value={this.state.stop_job}
                                        onKeyDown={keyPress}
                                        onChange={(event) => {
                                            // inline handling of the event, you can write your logic here.
                                            const newDate = event.target.value;
                                            // Do something with the newDate, for example, setting the state.
                                            this.setState({stop_job: newDate});
                                        }}
                                    />
                                </Stack>
                                <TextField
                                    id='notes_job'
                                    mt={2}
                                    multiline
                                    maxRows={25}
                                    fullWidth
                                    value={this.state.notes_job}
                                    onChange={(event, key) => {
                                        handleTextChange(event, 'notes_job')
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button name='cancel' type="submit">Cancel</Button>
                                <Button name='submit' type="submit">Confirm Changes</Button>
                            </DialogActions></form>
                    </Dialog>
                </Tooltip>
                {checkOutdated()}
            </Stack>
        )
    }
}

export default HireExtend;