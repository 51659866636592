import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Divider,
    TextField,
    Tooltip,
    IconButton
} from '@mui/material';
import React from "react";

import MoreTimeIcon from '@mui/icons-material/MoreTime';


export default function DLG_ButtonExtendHire(props) {

    const [submit, setSubmit] = React.useState("")
    const [clientList, setClientList] = React.useState([])
    const [sourceClient, setSourceClient] = React.useState(null)
    const [destinationClient, setDestinationClient] = React.useState(null)

    const handleDialogOpen = () => {
        props.dlgSetOpen(true);
    };

    React.useEffect(() => {
        fetch('/api/clients/get')
            .then((res) => res.json())
            .then((data) => {
                setClientList(data)
            });
    }, [props.dlgSetOpen]);
    React.useEffect(() => {
        console.log(sourceClient)
        console.log(destinationClient)
    }, [sourceClient, destinationClient]);

    function keyPress(e) {
        if (e.keyCode == 13) {
            console.log('value', e.target.value);
            var url = '/api/job/extend_confirmation/' + props.hashcode + '/' + e.target.value;
            fetch(url).then(response => response.json()).then(data =>
                handleDialogClose());
        }
    }

    function JobChange(event) {
        event.preventDefault()
        console.log(event.target.extend_by.value)
        var url = '/api/job/extend_confirmation/' + props.hashcode + '/' + event.target.extend_by.value;
        handleDialogClose()
        props.fn_alert("Time extension")
        fetch(url).then(response => response.json()).then(data => {
                console.log("extension sent")
                window.location.reload()
            }
        );
    }

    const MergeClients = (event) => {
        event.preventDefault()
        if (submit == "b1") {
            var option = "merge/"
        } else {
            var option = "delmerge/"
        }

        var url = '/api/clients/' + option +sourceClient+ '/' +destinationClient ;
        console.log(url)
        fetch(url).then(response => response.json()).then(data => {
                props.dlgSetOpen(false);
                //(event.target.Client.value)
                //window.location.reload()
            }
        );
    }
    const handleDialogClose = (data) => {
        props.dlgSetOpen(false)
        console.log(data)
    };

    const ChangeClient = (event, clientdetails, reason) => {
        if (reason !== 'reset' && clientdetails) {
            //console.log(props.client)
            setDestinationClient(clientdetails.id_client)
            setSourceClient(props.client[0])
        }
    };

    return (
        <Dialog
            open={props.dlgOpen}
            onClose={handleDialogClose}
        >
            <form onSubmit={MergeClients}>
                <DialogTitle>Merge</DialogTitle>
                <Divider/>
                <DialogTitle>{props.client[1]}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        with company selected from below<br/><span>(all records will be moved to selected client)</span>
                    </DialogContentText>
                    <Box mt={1
                    }>
                        <Autocomplete
                            freeSolo
                            id="client_name"
                            onChange={(event, newValue, reason, details) =>
                                ChangeClient(event, newValue, reason, details)
                            }
                            value=""
                            getOptionLabel={(option) => (option ? option.name_client : '')}
                            options={clientList}
                            renderOption={(props, option, {selected}) => {
                                return (
                                    <li
                                        {...props}
                                        key={option.id_client}
                                    >
                                        {option.name_client}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Select Client"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="outlined" color="secondary" type="submit" name="s1"
                            onClick={() => setSubmit("b1")}>Merge (Move)</Button>
                    <Button variant="outlined" color="error" type="submit" name="s2" onClick={() => setSubmit("b2")}>Merge
                        and Delete</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}