const themeClasses = [
    "finished",
    "current",
    "ending",
    "overdue",
    "waiting",
    "confirmed",
    "job_update",
    "job_extended",
    "job_reminder",
    "job_closed",
    "clear_schedule",
    "job_normal",
];

export const createDataGridStyles = (getBackgroundColor, getHoverBackgroundColor) => ({
    ...themeClasses.reduce((accumulator, themeClass) => {
        accumulator[`& .dg--${themeClass}`] = {
            fontSize: "12px",
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette[themeClass].main, theme.palette.mode),
            "&:hover": {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette[themeClass].main,
                        theme.palette.mode
                    ),
            },
        };
        return accumulator;
    }, {}),
    "& .error-row": {
        color: '#FF6633',
    },
});