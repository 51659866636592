import React from "react";
import {Outlet,  } from "react-router-dom";
import { createTheme, ThemeProvider} from '@mui/material/styles';
export default function Reminder() {
    const [clients, setClients] = React.useState({});
    const theme2 = createTheme({

        root: {
            width: '100%',
          },
        palette: {
            primary: {
              main: '#2b424f',
              dark: '#2b424f',
            },
            secondary: {
              main: '#b03bb7',
            },
            warning: {
              main: '#39944a',
            },
            error: {
              main: '#ff3b80',
            },
            background: {
                default: '#a400a9',
                paper: '#f9edf3',
            },
            color: {main:'#FF0'},
      },
      typography: {
        fontFamily: 'Montserrat',
        fontWeightBold: 1000,
        fontWeightMedium: 600,
      },
    });
    const [count, setCount] = React.useState(0);
    React.useEffect(() => {
        fetch('/api/clients/get').then(res => res.json()).then(data => {
            setClients(data)
        });
    }, []);
    return (
        <ThemeProvider theme={theme2}>
        <div>
            <Outlet/>
        </div>
        </ThemeProvider>
    );
}