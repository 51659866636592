import {
    Paper, Checkbox,
    FormControl,
    Grid, InputAdornment,
    Stack,
    TextField,
    Typography,
    Divider
} from "@mui/material";

import { styled } from '@mui/material/styles';
import React from "react";
import { teal } from '@mui/material/colors';
import {Item, Item2, Item2off} from "./STL_Item";

class CalcItem extends React.Component {

        constructor(props) {
        super(props);
            this.state = {
                active:false,
                price:0,
                length:0,
                height:0,
                depth:0,
                finalprice:0,
            }
        }

     componentDidMount() {

        if (this.props.loadedAsset){
            this.setState({
                active:this.props.loadedAsset.active||false,
                price:this.props.loadedAsset.price,
                length:this.props.loadedAsset.dimensions.length||null,
                height:this.props.loadedAsset.dimensions.height||null,
                depth:this.props.loadedAsset.dimensions.depth||null,
                finalprice:this.props.loadedAsset.finalprice,
            })
        }
     }

     componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state.price ===0){
            this.setState({price:this.props.vars.price})
        }


        if (this.state != prevState) {
            if (this.state.length != prevState.length || this.state.height != prevState.height || this.state.depth != prevState.depth || this.state.price != prevState.price || this.state.active != prevState.active) {
                let _finalprice = 0
                let _dimensions = {}
                if (!this.state.active){
                    this.setState({length:0, hieght:0, depth:0})
                }
                switch (this.props.vars.calc){
                    case ('volume'):
                        _finalprice = this.state.length * this.state.height * this.state.depth * this.state.price
                        _dimensions = {"length":parseFloat(this.state.length), "height":parseFloat(this.state.height), "depth":parseFloat(this.state.depth)}
                        break;
                    case ('area'):
                        _finalprice = this.state.length * this.state.height * this.state.price
                        _dimensions = {"length":parseFloat(this.state.length),  "height":parseFloat(this.state.height)}
                        break;
                    default:
                        _finalprice = this.state.length * this.state.price
                        _dimensions = {"length":parseFloat(this.state.length)}
                        break;
                }
                this.setState({finalprice: _finalprice})
                this.props.fn_update({
                'price': this.state.price,
                'finalprice': _finalprice,
                'dimensions': _dimensions,
                'comment': '',
                'active': this.state.active,
                })
            }
        }

     }

    render() {

            const TF = (key, adr) =>{
                return (
                    <TextField
                        onChange={(event) => {this.setState( {[key]:event.target.value})}}
                        required
                        margin="none"
                        size="small"
                        label={key}
                        name="price"
                        value={this.state[key]}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{adr}</InputAdornment>,
                        }}
                    />
                )
            }
        const Disabled = () =>{
            return(
                <Grid container>
                    <Grid item xs={10} >
                        <Typography pb={1} align="center" sx={{ fontSize: 14}} color="Gray" >{this.props.vars.name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="right" sx={{ fontSize: 10}} color="text.secondary" >£{this.props.vars.price}</Typography>
                    </Grid>
                </Grid>
            )
        }
        const Enabled = () =>{
            return(
                <Stack direction="row" alignItems="center">
                        <Typography width='90%' sx={{ fontSize: 14, fontWeight:600}} color='#69A9A3'>{this.props.vars.name}</Typography>
                    <Stack width='23%'>
                        <TextField
                                              onChange={(event) => {this.setState( {price:event.target.value})}}
                                              required
                                              margin="dense"
                                              size="small"
                                              label="price/unit"
                                              name="price"
                                              value={this.state.price}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                            />
                            </Stack>
                </Stack>
            )
        }

        const renderCalc = () => {
                switch (this.props.vars.calc){

                    case ('volume'):
                        return(
                            <Stack direction='row' width="100%">
                                {TF("length", "m")}
                                {TF("height", "m")}
                                {TF("depth", "m")}
                            </Stack>
                        )
                        break
                    case ('area'):
                        return(
                            <Stack direction='row' width="100%">
                                {TF("length", "m")}
                                {TF("height", "m")}
                            </Stack>
                        )
                        break
                    default:
                        return(
                            <Stack direction='row' width="100%">
                                {TF("length", "m")}
                            </Stack>
                        )
                        break
                }
        }

        return (
            <FormControl fullWidth key={this.props.uid}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={1} m={-1}>
                            <Checkbox checked={this.state.active} onChange={(event) =>this.setState({ active: event.target.checked })} name={this.props.vars.name} sx={{color: teal[500], '&.Mui-checked': {color: teal[300],},}}/>
                        </Grid>
                        <Grid item xs={11}>
                            {this.state.active ?<Enabled/>:<Disabled/>}
                        </Grid>
                    </Grid>
                <Divider/>
                {this.state.active ?
                     <Grid container alignItems="center">
                           <Grid container>
                                <Grid item xs={9.5}>
                                    <Item>
                                        {renderCalc()}
                                    </Item>
                                </Grid>
                                <Grid item xs={2.5}>
                                    {this.state.finalprice>0?<Item2>
                                          <TextField
                                          required
                                          margin="none"
                                          size="small"
                                          label="cost (£)"
                                          name="price"
                                          value={this.state.finalprice}
                                        />
                                    </Item2>:
                                    <Item2off>
                                          <TextField
                                          required
                                          margin="none"
                                          size="small"
                                          label="cost (£)"
                                          name="price"
                                          value={this.state.finalprice}
                                        />
                                    </Item2off>
                                    }
                                </Grid>
                            </Grid>
                     </Grid>:null
                    }
            </FormControl>
        )
    }
}
export default CalcItem;