import { Autocomplete, Button, Grid, Box, TextField, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QuoteList from "./Controls/CTRL_QuoteList";
import ClientList from "./Controls/CTRL_ClientList";
import JobList from "./Controls/CTRL_JobList";

import React from "react";

class QuoteSelector extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        quotes: {},
        name: null,
        id_quote: null,
        id_hire: null,
        id_client: null,
    };
    }

    componentDidMount() {}


    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
    const updateQuoteControl = (option, ) => {
        this.setState((prevState) => ({
            ...prevState,
            id_quote: option.id_quote || null,
            id_client: option.id_client || null,
        }));
        //this.props.fn_update(option.id_quote)
    };

    const updateDialog = (action, option) => {
        switch (action){
          case "import":
              this.props.fn_update(action, option)
              break;
          case "save":
              this.setState((prevState) => ({
                ...prevState,
                id_client: option,
                  }), () => {
                    // set the updated id_client as the selectedClient prop
                    this.forceUpdate();
                  });
              break;
          default:


              break;



        }
    };

    const updateClient = (client) => {
      this.setState((prevState) => ({
        ...prevState,
        id_client: client.client_id,
      }));
    };

    const updateHire = () => {};

    const checkQuoteJob = (event, clientname, reason, details) => {};

    if (!this.state.quotes) {
      return <div>Loading...</div>;
    }

    return (
      <Grid container>
        <Grid item xs={0.75}>
          <Button disabled={!this.state.id_quote} onClick={(event) => this.props.fn_update("preview")}>
            Preview
          </Button>
        </Grid>
        <Grid item xs={0.75}>
          <Button disabled={!this.state.id_quote} onClick={() => this.props.fn_update("import", 1)}>
            Import
          </Button>
        </Grid>
        <Grid item xs={3}>
          <QuoteList fn_update={updateQuoteControl}/>
        </Grid>
        <Grid item xs={0.75}>
          {!this.state.id_quote ? (
            <Button disabled={this.state.id_hire} onClick={() => updateDialog("save", 10)}>
              Save
            </Button>
          ) : (
            <Button onClick={() => updateDialog("change", this.state.id_client)}>Change</Button>
          )}
        </Grid>
        <Grid item xs={0.75}>
          <Button disabled={!this.state.id_quote} onClick={(event) => this.props.fn_update("clone")}>
            Clone
          </Button>
        </Grid>
        <Grid item xs={3}>
          <JobList fn_update={updateHire} />
        </Grid>
        <Grid item xs={3}>
          <ClientList selectedClient={this.state.id_client} fn_update={updateClient} />
        </Grid>
      </Grid>
        )
    }
}
export default QuoteSelector;