import {
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    Divider,
    Select,
    MenuItem
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { teal } from '@mui/material/colors';
import {Item, Item2, Item2off} from "./STL_Item"
import React from "react";


class CalcItemMenu extends React.Component {
        constructor(props) {
        super(props);
            this.state = {
                active:false,
                option:0,
                price:0,
                length:0,
                height:0,
                depth:0,
                finalprice:0,
                menuoption:'',
                price_modifier:0,
            }
        }



    updateCalculation () {


    }


    componentWillUnmount() {
        this.props.fn_update({
            'option':0,
            'price': 0,
            'finalprice': 0,
            'dimensions': {},
            'comment': '',
            'active': false,
        })
    }
    componentDidMount() {

            if(this.props.segment){
                console.log(this.props.segment.price_modifier)
              this.setState({
                active: this.props.vars.active,
                name: this.props.vars.name,
                price_modifier: parseFloat(this.props.segment.price_modifier),
              });
            }

            if (this.props.loadedAsset){
            this.setState({
                option:this.props.loadedAsset.option||0,
                price:this.props.loadedAsset.price,
                length:this.props.loadedAsset.dimensions.length||null,
                height:this.props.loadedAsset.dimensions.height||null,
                depth:this.props.loadedAsset.dimensions.depth||null,
                finalprice:this.props.loadedAsset.finalprice,
                active:this.props.loadedAsset.active||false,
                menuoption:this.props.loadedAsset.menuoption||false,
                price_modifier:this.props.loadedAsset.price_modifier||0,
            })

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){

            if (this.props.segment && prevProps.segment.price_modifier != this.props.segment.price_modifier){
                this.setState({
                  price_modifier: parseFloat(this.props.segment.price_modifier),
                });
            }

        if (this.state != prevState) {

             let _finalprice = 0
             let _dimensions = {}

            if  (this.state.active != prevState.active){
                            this.props.fn_update({
                            'option':0,
                            'price': 0,
                            'finalprice': 0,
                            'dimensions': {},
                            'comment': '',
                            'active': false,
                        })
            }

            if (!this.state.price){
                this.setState({price:this.props.vars.menulist[0].price})
                //this.refPrice.current.value = this.props.vars.menulist[0].price
            }
            if (this.state.length != prevState.length  || this.state.height != prevState.height || this.state.depth != prevState.depth || this.state.price != prevState.price) {
                this.setState({finalprice: (this.state.length * this.state.height  * this.state.depth * (this.state.price))})
                //this.setState({menuoption: this.props.vars.menulist[0].name})
            }
                 if (this.state.length != prevState.length
                     || this.state.height != prevState.height
                     || this.state.depth != prevState.depth
                     || this.state.price != prevState.price
                     || this.state.active != prevState.active
                     || this.state.menuoption != prevState.menuoption
                     || this.state.price_modifier != prevState.price_modifier
                 ) {


                     if (!this.state.active){
                            this.props.fn_update({
                            'option':0,
                            'price': 0,
                            'finalprice': 0,
                            'dimensions': {},
                            'comment': '',
                            'active': false,
                            'pricemodifier': 0,
                        })
                    }
                    switch (this.props.vars.calc){
                        case ('volume'):
                            _finalprice = this.state.length * this.state.height * this.state.depth * (this.state.price+this.state.price_modifier)
                            _dimensions = {"length":parseFloat(this.state.length), "height":parseFloat(this.state.height), "depth":parseFloat(this.state.depth)}
                            break;
                        case ('area'):
                            _finalprice = this.state.length * this.state.height * (this.state.price+this.state.price_modifier)
                            _dimensions = {"length":parseFloat(this.state.length),  "height":parseFloat(this.state.height)}
                            break;
                        default:
                            _finalprice = this.state.length * (this.state.price+this.state.price_modifier)
                            _dimensions = {"length":parseFloat(this.state.length)}
                            break;
                    }

                     if (this.props.segment){

                     }
                    this.props.fn_update({
                        'option':this.state.menuoption,
                        'price': this.state.price,
                        'finalprice': _finalprice,
                        'dimensions': _dimensions,
                        'comment': '',
                        'active': this.state.active,
                        'pricemodifier': this.state.pricemodifier,
                    })

                    this.setState({finalprice: _finalprice})
                }
            }
        }

    render() {
        const renderCalc = () => {
            switch (this.props.vars.calc){
                case ('volume'):
                    return(
                        <Stack direction='row' width="100%">
                            {TF("length", "m")}
                            {TF("height", "m")}
                            {TF("depth", "m")}
                        </Stack>
                    )
                    break
                case ('area'):
                    return(
                        <Stack direction='row' width="100%">
                            {TF("length", "m")}
                            {TF("height", "m")}
                        </Stack>
                    )
                    break
                default:
                    return(
                        <Stack direction='row' width="100%">
                            {TF("length", "m")}
                        </Stack>
                    )
                    break
            }
        }

        const SelectItem = (props) => {
            return(
            <Select
                value = {this.state?.option ?? null}
                size="small"
                onChange={(event)=>{
                    this.setState({option:event.target.value});
                    this.setState({menuoption:this.props.vars.menulist[event.target.value].name});
                    this.setState({price:this.props.vars.menulist[event.target.value].price})}
                }
                fullWidth={true}
                >
                {
                props.vars.menulist?.map((item,index)=> (
                    <MenuItem key={index} value={index}>{item.name}</MenuItem>
                 ))
                }
            </Select>
            )
        }

        const TextItemPrice = () => {
            return(
            <TextField
              key={this.props.id+"_"+this.props.vars.name}
              onChange={(event) => {this.setState({price:event.target.value})}}
              required
              margin="dense"
              size="small"
              label="price/unit"
              name="price"
              value={this.state.price}
              InputLabelProps={{
                shrink: true,
              }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            )
        }
        const ModifierPrice = () => {
            return(
            <TextField
              key={this.props.id+"_"+this.props.vars.name}
              onChange={(event) => {this.setState({price:event.target.value})}}
              required
              margin="dense"
              size="small"
              label="price/unit"
              name="price"
              value={this.state.price_modifier}
              InputLabelProps={{
                shrink: true,
              }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{'+'}</InputAdornment>,
                    endAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            )
        }

        const TF = (key, adr) =>{
            return (
                <TextField
                  onChange={(event) => {this.setState( {[key]:event.target.value})}}
                  required
                  margin="none"
                  size="small"
                  label={key}
                  name="price"
                  value={this.state[key]}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">{adr}</InputAdornment>,
                    }}
                />
            )
        }

        const Disabled = () =>{
            return(
                <Grid container>
                    <Grid item xs={10}pb={1}>
                        <Typography align="center" sx={{ fontSize: 14}} color="Gray" >{this.props.vars.name}</Typography>
                    </Grid>
                    <Grid item xs={12}><Divider/></Grid>
                </Grid>
            )
        }
        const Enabled = () =>{
            return(
                <Grid alignItems="center">
                        <Typography width='90%' sx={{ fontSize: 14, fontWeight:600}} color='#69A9A3'>{this.props.vars.name}</Typography>
                </Grid>
            )
        }

        return (
            <FormControl fullWidth key={this.props.vars.name+"_"+this.props.id}>
                <Stack width='100%'>
                        {this.props.vars.optional?
                            <Grid container alignItems="flex-end">
                                <Grid item xs={1} m={-1}>
                                    <Checkbox checked={this.state.active} onChange={(event) =>this.setState({ active: event.target.checked })} name={this.props.uid} sx={{color: teal[500], '&.Mui-checked': {color: teal[300],},}}/>
                                </Grid>
                                <Grid item xs={11}>
                                    {this.state.active ?<Enabled/>:<Disabled/>}
                                </Grid>
                            </Grid>:
                            <Grid item xs={12}>
                                 <Typography width='80%' sx={{ fontSize: 14, fontWeight:600}} color='#69A9A3'>{this.props.vars.name}</Typography>
                            </Grid>
                        }
                        {this.state.active ?
                        <Stack>
                            <Grid item xs={12}>
                                 <Grid container alignItems="top">
                                    <Grid item xs={7.8} pr={0.5}>
                                          <SelectItem name={this.props.uid} vars={this.props.vars}/>
                                    </Grid>
                                    <Grid item xs={2} mr={0.1}>
                                        <TextItemPrice/>
                                    </Grid>
                                    <Grid item xs={2} mr={0.1}>
                                        <ModifierPrice/>
                                    </Grid>
                                 </Grid>
                            </Grid>
                           <Stack direction='row'>
                            <Grid container alignItems="center">
                                <Grid container>
                                    <Grid item xs={9.5}>
                                        <Item>
                                            {renderCalc()}
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        {this.state.finalprice>0?<Item2>
                                              <TextField
                                              margin="none"
                                              size="small"
                                              label="cost (£)"
                                              name="price"
                                              value={this.state.finalprice}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                        </Item2>:
                                        <Item2off>
                                              <TextField
                                              margin="none"
                                              size="small"
                                              label="cost (£)"
                                              name="price"
                                              value={this.state.finalprice}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                        </Item2off>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                        </Stack>
                        :""}
                </Stack>
            </FormControl>
        )
    }
}

export default CalcItemMenu;