import {Paper, Box} from '@mui/material';
import DLG_NavBar from "./DLG_NavBar";
import LogDataGrid from "./Controls/CTRL_LogDataGrid";
import React from "react";
import {darken, lighten} from "@mui/material/styles";

export default function MainLogs() {

    const [logs, setLogs] = React.useState([]);
    const [gridHeight, setGridHeight] = React.useState(0);

React.useEffect(() => {
    const calcGridHeight = () => {
      // Set the grid height based on the window height and bottom nav height
      const bottomNavHeight = 56; // Adjust this value based on your bottom nav height
      setGridHeight(window.innerHeight - bottomNavHeight);
    };

    // Calculate the grid height on initial load
    calcGridHeight();

    // Add an event listener to handle window resize
    window.addEventListener("resize", calcGridHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", calcGridHeight);
    };
  }, []);

React.useEffect(() => {
fetch('/api/events',
            {
                method: "POST",
                body: JSON.stringify({
                    id_user: 1
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(res => res.json()).then(data => {
                setLogs(data)
            //window.location.reload();
        });
    }, []);


return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              //minHeight: "100vh", // Set the minimum height to the full viewport height
            }}
          >
            <Box sx={{ flexGrow: 1, height: "100%" }}>
              <LogDataGrid data={logs} gridHeight={gridHeight} />
            </Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={10}>
                <DLG_NavBar highlight={"Logs"}/>
            </Paper>
        </Box>
    );
}