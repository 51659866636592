import {
    IconButton,
    FormControl,
    MenuItem,
    Select,
    Stack,
    TextField, ToggleButton, Tooltip
} from "@mui/material";
import DiscountIcon from '@mui/icons-material/Discount';
import React from "react";

class PaidSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.ispaid.paid_job,
            discount: false,
        };

    }

    componentDidMount() {
        this.setState({discount: this.props.ispaid.price_discount})
        if (this.props.ispaid.paid_job === "Partially") {
            this.setState({status: "PartPaid"})
        } else {
            this.setState({status: this.props.ispaid.paid_job})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.state)
        if (prevProps.ispaid.paid_job != this.props.ispaid.paid_job) {
            if (this.props.ispaid.paid_job === "Partially") {
                this.setState({status: "PartPaid"})
            } else {
                this.setState({status: this.props.ispaid.paid_job})
            }
        }
        if (prevProps.ispaid.price_discount != this.props.ispaid.price_discount) {
            //this.setState({discount: this.props.ispaid.price_discount === true})
        }
    }

    render() {

        if (!this.props.ispaid) {
            return <div>Loading...</div>;
        }

        const statusColour = () => {
            switch (this.state.status) {
                case 'Paid':
                    return ({backgroundColor: 'mediumseagreen', fontWeight: 'bold',});
                case 'PaidDiscount':
                    return ({backgroundColor: 'darkgreen'});
                case 'Discount':
                    return ({backgroundColor: 'black'});
                case 'PartPaid':
                    return ({backgroundColor: 'sandybrown', fontWeight: 'bold',});
                case 'Partially':
                    return ({backgroundColor: 'sandybrown', fontWeight: 'bold',});
                case 'PartPaidDiscount':
                    return ({backgroundColor: 'chocolate'});
                case 'NotPaidDiscount':
                    return ({backgroundColor: 'maroon'});
                default:
                    return ({backgroundColor: 'red'});
            }
        }

        const paidUpdate = (event) => {
            let data = {
                'update_column': 'job',
                'id_job': this.props.ispaid.id_job,
                'update': {
                    'paid_job': event.target.value,
                }
            }
            this.setState({status: event.target.value})
            fetch('/api/job/updatepart', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data),
            }).then(res => res.json()).then(data => {
                this.props.fn_update()
            });
        }
        const discountUpdate = (event) => {
            console.log(event.currentTarget.value)
            let data = {
                'update_column': 'job',
                'id_job': this.props.ispaid.id_job,
                'update': {
                    'price_discount': (this.state.discount),
                }
            }
            if (event.currentTarget.value === 'True') {
                this.setState({discount: 'False'})
                data.update.price_discount = false
            } else {
                this.setState({discount: 'True'})
                data.update.price_discount = true
            }
            fetch('/api/job/updatepart', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data),
            }).then(res => res.json()).then(data => {
                this.props.fn_update()
            });
        }
        if (!this.props.ispaid.id) {
            return <div>Loading...</div>;
        }
        return (
            <Stack direction='row' spacing={.3}>
                <Stack width='20%'>
                    <IconButton onClick={(event) => discountUpdate(event, this.props.ispaid.id)}
                                value={this.state.discount}
                                color={this.state.discount == 'True' ? "active" : "primary"}><Tooltip
                        title={this.state.discount == 'True' ? "Discount applied" : "No discount"}><DiscountIcon/></Tooltip></IconButton>
                </Stack>
                <Stack width='90%'>
                    <Select
                        size="small"
                        margin="dense"
                        sx={statusColour}
                        name={"key" + String(this.props.ispaid.id)}
                        value={this.state.status}
                        onChange={(event, uid) => paidUpdate(event, this.props.ispaid.id)}
                    >
                        <MenuItem value={"Paid"}>Fully Paid</MenuItem>
                        <MenuItem value={"NotPaid"}>Not Paid</MenuItem>
                        <MenuItem value={"PartPaid"}>Paid Part</MenuItem>
                        <MenuItem value={"Discount"}>Discount</MenuItem>
                    </Select>
                </Stack>
            </Stack>
        );
    }
}

export default PaidSelect;