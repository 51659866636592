import {ToggleButtonGroup, ToggleButton, Select, MenuItem, Checkbox, FormGroup, FormLabel, FormControlLabel, Slider, Paper, BottomNavigation, Grid, TextField, Divider} from '@mui/material';
import React from "react";
import ReactDOM from "react-dom/client";


function handleChange(event){
    console.log(event.target.value)
}



export default function DLG_HireFilter(props) {
    const [config, setConfig] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const highlightSearch = event => {
         console.log(event.target.value)
    };

    const fetchData = () => {
          fetch('/api/config').then(res => res.json()).then(data => {
            setIsLoading(false);
            setConfig(config.concat(data))
            //setConfig(['finished'?'finished':'', 'ending'?'ending':'', 'current'?'current':'', 'overdue'?'overdue':'', 'waiting'?'waiting':'', ]);

          }).catch((error) => {
            setIsLoading(false);
            setIsError(true);
        });
    };
    const  setFilter = event => {
        fetch('/api/filter',
            {
                method: "POST",
                body: JSON.stringify({
                    name_filter: event.target.name,
                    enabled: event.target.checked,
                    id_user: 1
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(res => res.json()).then(data => {
            window.location.reload();
        });
    }

    const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newConfig: string[],
    ) => {
        setConfig(newConfig);
        fetch('/api/filter',
            {
                method: "POST",
                body: JSON.stringify(
                    newConfig
                ),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(res => res.json()).then(data => {
            window.location.reload();
        });
  };
    const  setFilterB = event => {

        fetch('/api/filter',
            {
                method: "POST",
                body: JSON.stringify({
                    name_filter: event.target.name,
                    enabled: event.target.value,
                    id_user: 1
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(res => res.json()).then(data => {
            window.location.reload();
        });
    }
    React.useEffect(() => {
        fetchData();
      }, []);
    if (isLoading) {
        return <div>Loading...</div>;
      }
    return (
        <Grid container spacing={1} alignItems="" justifyContent="center" mt={0.3}>
            {/*
            <Grid item xs={2}>
                <Slider
        aria-label="Temperature"
        defaultValue={30}
        valueLabelDisplay="auto"
        step={10}
        marks
        min={10}
        max={110}
      />
                </Grid>
            */}
            <Grid>
                <ToggleButtonGroup value={config} onChange={handleFormat}>
                    <ToggleButton value="finished">Closed jobs</ToggleButton>
                    <ToggleButton value="ending">Ending soon</ToggleButton>
                    <ToggleButton value="current">In progress</ToggleButton>
                    <ToggleButton value="overdue">Overdue</ToggleButton>
                    <ToggleButton value="waiting">Waiting for confirmation</ToggleButton>
                </ToggleButtonGroup>
                </Grid>
            <Grid item xs={2}>

            </Grid>
        </Grid>
        );
    }