import {useParams} from "react-router-dom";
import React from "react";
import {Box, Card, CardContent, CardActions, Divider, Grid, Link, Typography, Stack, Button, ButtonGroup} from '@mui/material';

export default function ClientShow () {

    const [jobs, setJobs] = React.useState([]);
    const [waiting, setWaiting] = React.useState("None");
    let {userID} = useParams();
    let url =  '/api/job/details/'+userID;
        React.useEffect(() => {
        fetch(url).then(res => res.json()).then(data => {
            setJobs(data);
            setWaiting(data.schedule_type);
            console.log(data)
        });
    }, []);


        function extend_job(event){
            var url = '/api/job/extend_request/'+event.target.value+'/'+event.target.id;
            setWaiting("");
            fetch(url).then(response => response.json()).then(data => {
                    console.log(data);
                }
            );

        }
        function close_job(event){
            console.log(event.target.value)
            console.log(event.target.id)
            setWaiting("");
            var url = '/api/job/close_request/'+event.target.value+'/'+event.target.id;
            fetch(url).then(response => response.json()).then(data => {
                    console.log(data);
                }
            );
        }
        function unsubscribe(param){
            setWaiting("");
            var url = '/api/clients/unsubscribe/'+param
            fetch(url).then(response => response.json()).then(data => {
                    console.log(data);
                }
            );
        }

    let update_waiting =
        <div>
            <Typography gutterBottom variant="h6" component="div">
            Your request was sent, you can close this window now
            </Typography>
        </div>

    let update_ready  =
    <div>
         <CardContent>
             <Box component="img" src="/static/logo.png"/>
            <Typography gutterBottom variant="h6" component="div">
                {jobs.name_client} this is reminder for your hire at:
            </Typography>
            <Typography gutterBottom variant="h3" component="div"mt={-2}>
                {jobs.address_hire}
            </Typography>
            <Typography variant="h3" color="red">
                {jobs.overdue>0 ? <b>Days overdue: {Math.abs(jobs.overdue)} (weeks: {Math.round(jobs.overdue/7)})</b>:<b>Days remaining: {Math.abs(jobs.overdue)}</b>}
            </Typography>
            <Typography variant="h5" color="text.secondary" mt={2}>
                Start date:  <b>{jobs.start_job}</b>
            </Typography>
            <Typography variant="h5" color="text.secondary" mt={2}>
                Expected end date:  <b>{jobs.stop_job}</b>
            </Typography>
            <Typography variant="h5" color="text.secondary" mt={3}>
                {jobs.clientnotes_job ? <span>Job description:   <b>{jobs.clientnotes_job}</b></span>:""}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" mt={5}>
                Please select action, you can extend your hire, or close it. Please select an option and we will send confirmation to your email
            </Typography>
        </CardContent>
        <CardActions>
            <Grid container>
                <Grid item xs={4}>
                    <Stack><Button variant='contained' disableRipple>Yes, I want to extend hire (click to extend)</Button>
                    <ButtonGroup fullWidth>
                    <Button value={jobs.hashcode_job} id={1} onClick={extend_job}>1 week</Button>
                    <Button value={jobs.hashcode_job} id={2} onClick={extend_job}>2 weeks</Button>
                    <Button value={jobs.hashcode_job} id={3} onClick={extend_job}>3 weeks</Button>
                    <Button value={jobs.hashcode_job} id={4} onClick={extend_job}>4 weeks</Button>
                    <Button value={jobs.hashcode_job} id={0} onClick={extend_job}>Call me</Button>
                    </ButtonGroup>
                    </Stack>
                  </Grid>
                <Grid item xs={2}>
                    <Typography align="right" variant="h6">You can call us:&nbsp;</Typography>
                  </Grid>
                <Grid item xs={2}>
                    <Typography align="left" variant="h6">+44 203 633 40 13</Typography>
                    <Typography align="left" variant="h6">+44 7921 831 425</Typography>
                    <Typography align="left" variant="h6">+44 7504 645 372</Typography>
                    <Typography align="left"></Typography>
                  </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Button variant='contained' disableRipple>No, I want to close hire (click to close)</Button>
                        <ButtonGroup fullWidth>
                        <Button value={jobs.hashcode_job} id={1} onClick={close_job}>Close {jobs.stop_job}</Button>
                        <Button value={jobs.hashcode_job} id={0} onClick={close_job}>Call me</Button>
                        </ButtonGroup>
                    </Stack>
                  </Grid>
                <Grid item mt={2} mb={2} xs={12}>
                <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center">If you want to unsubscribe from our mailing service please clicke on the button below.</Typography>
                    <Link
                      component="button"
                      variant="h6"
                      onClick={() => {unsubscribe(jobs.name_client);}}>
                      Unsubscribe (window will close permanently)
                    </Link>
                </Grid>
            </Grid>
        </CardActions>
    </div>
    return (
        <Card sx={{ maxWidth: "100%", }} >
            {waiting == "None" || waiting=="reminder" ? update_ready: update_waiting}
        </Card>
    )
}