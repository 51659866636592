import {InputAdornment, Divider, ToggleButton, Switch, Autocomplete, Button, Checkbox, Select, MenuItem, OutlinedInput, Grid, Dialog, DialogTitle, DialogContent, InputLabel, FormControl, FormControlLabel,NativeSelect, Stack, TextField, DialogActions, Tooltip, Paper, Box, Typography, IconButton} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {format} from 'date-fns'
import React from "react";

class CTRL_AddJob extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            wide:false,
            active: null,
            price: null,
            result: {},
            supervisors: ["", "Artur", "Bogdan", "Dawid", "Ewa", "Grzegorz", "Tomasz"],
            stopDate: null,
            startDate: null,
            initialStopDate: null,
            weekAdjVisible: false,
            weeksDiff: 0,
            header: {title: "EDIT JOB"},
            submitForm: "",
            currentClient:null,
            initialJobForm: {
                address_hire: "",
                postcode_hire: "",
                invoice_hire: 0,
                client_name: "",
                client_id: "",
                type_job: "",
                start_job: null,
                stop_job: null,
                finished_job: "",
                paid_job: "NotPaid",
                amountpaid_job: "",
                supervisor_hire: "",
                inspection_hire: false,
                alarm_hire: false,
                licence_hire: false,
                lights_hire: false,
                sell_hire: false,
                hoarding_hire: false,
                hashcode_job: "",
                hashcode_hire: "",
                clientnotes_job: "",
                notes_job: "",
                name_client: "",
                price_discount: false,
                mail_notification: false,
                id_hire:null,
            },
            jobForm: {            },
        }
    }

    componentDidMount() {
        console.log(this.props.clients)
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        this.setState(prevState => ({jobForm:{                   // object that we want to update
            ...prevState.jobForm,    // keep all other key-value pairs
            start_job: date,    // update the value of specific key
            }
        }))
        //this.setState({jobForm: this.initialJobForm})

        {/*
                        if (this.props.dlgOpen){
                    if (this.props.job_details){
                        if (this.props.job_details.stop_job!=""){
                            setWeekAdjVisible(true)
                            setInitialStopDate(this.props.job_details.stop_job)
                        }
                        setJobForm(this.props.job_details)
                        if (this.props.job_details.hashcode_job === ""){
                            setText({...text, title:"Add job: "})
                        }else{
                            setText({...text, title:"Edit job: "})
                        }
                    }else{
                        console.log("No details")
                    }


                }

        }, [this.props.dlgOpen, this.props.job_details.hashcode_job]

        */}
        this.setState( {jobForm: this.props.job_details})
        this.setState( {weeksDiff:Math.round((new Date(this.state.jobForm.stop_job) - new Date(this.state.jobForm.start_job)) / 604800000)})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
        if (prevState.jobForm.stop_job != this.state.jobForm.stop_job){
            this.setState({weeksDiff: Math.round((new Date(this.state.jobForm.stop_job) - new Date(this.state.jobForm.start_job)) / 604800000)})
        }


        if (prevState.jobForm.start_job != this.state.jobForm.start_job){

            if (this.state.jobForm.stop_job === "" || this.state.jobForm.stop_job<this.state.jobForm.start_job) {
                this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    stop_job: this.state.jobForm.start_job,    // update the value of specific key
                    }
                }))
                this.setState({initialStopDate:this.state.jobForm.start_job})
                this.setState({weekAdjVisible:true})
            }
        }
    if (prevProps.job_details != this.props.job_details){
            if (this.props.job_details.start_job) {
                this.setState({jobForm: this.props.job_details})
                this.setState({header: {title: "EDIT JOB"}})

            }else{
                const current = new Date();
                const date = `${current.getFullYear()}-${formatTwoDigits(current.getMonth()+1)}-${formatTwoDigits(current.getDate())}`;

                this.setState({jobForm: this.state.initialJobForm})
                this.setState({header: {title: "ADD JOB"}})
                this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    start_job: date,    // update the value of specific key
                    stop_job: date,    // update the value of specific key
                    }
                }))
            }
        }
    }



    render() {

        const calcWeeksDiff = () => {
            this.setState({weeksDiff: Math.round((new Date(this.state.jobForm.stop_job) - new Date(this.state.jobForm.start_job)) / 604800000)})
        }

        const handleClose = () => {
            this.props.dlgSetOpen(false)
            //this.setState(initialJobForm)
        }

        const handlePaidSelect = (event: SelectChangeEvent) => {
            this.setState(prevState => ({jobForm:{                   // object that we want to update
                ...prevState.jobForm,    // keep all other key-value pairs
                paid_job: event.target.value       // update the value of specific key
                }
            }))
        }
        const CheckboxChange = (event) => {
            this.setState(prevState => ({jobForm:{                   // object that we want to update
                ...prevState.jobForm,    // keep all other key-value pairs
                [event.target.id]: event.target.checked       // update the value of specific key
                }
            }))
        }
        const FieldChange = (event) => {
            this.setState(prevState => ({jobForm:{                   // object that we want to update
                ...prevState.jobForm,    // keep all other key-value pairs
                [event.target.id]: event.target.value       // update the value of specific key
                }
            }))
        }
        const GetInvoice = () => {
            console.log(this.props.fn_getinvoice())
        }

        const ChangeAddress = (event, newValue, reason) => {
            if (reason != "reset") {
                this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    address_hire: newValue,       // update the value of specific key,
                    id_hire: event.target.getAttribute('data-option-index')       // update the value of specific key
                    }
                }))
            }
        }
        const ChangeClient = (event, newValue, reason) => {
            if (reason != "reset") {
                this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    name_client: newValue       // update the value of specific key
                    }
                }))
            }
        }
        const ChangeSupervisor = (event, newValue, reason) => {
            console.log(reason)
            if (reason != "reset") {
                    this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    supervisor_hire: newValue      // update the value of specific key
                    }
                }))
            }
        }

        const handleHireStart = (event) => {
            this.setState(prevState => ({jobForm:{                   // object that we want to update
                ...prevState.jobForm,    // keep all other key-value pairs
                start_job: event.target.value      // update the value of specific key
                }
            }))

            calcWeeks()
        }

        const updateJobForm = () => {
            this.setState(prevState => ({jobForm:this.props.job_details}))
        }

        const handleHireEnd = (event) => {
            if (event.target.value) {
                //setInitialStopDate(event.target.value)
                this.setState(prevState => ({jobForm:{                   // object that we want to update
                    ...prevState.jobForm,    // keep all other key-value pairs
                    stop_job: event.target.value      // update the value of specific key
                    }
                }))
                this.setState({weekAdjVisible:true})
                var date = new Date(event.target.value);
                console.log(event.target.value)
                calcWeeks(this.setState({weeksDiff:(date - new Date(this.state.jobForm.start_job)) / (1000 * 60 * 60 * 24 * 7)}))
            } else {
                this.setState({weekAdjVisible:false})
            }
        }

        const calcWeeks = (date) => {
            {
                if (Number.isInteger(date)) {
                    console.log(date)
                } else {
                    let weeks = Math.round((new Date(this.state.jobForm.stop_job) - new Date(this.state.jobForm.start_job)) / (1000 * 60 * 60 * 24 * 7))
                    console.log("dates", this.state.jobForm.stop_job, this.state.jobForm.start_job)
                    this.setState({weeksDiff:weeks})
                    console.log("weeks: ", this.state.weeksDiff)
                }
            }
        }

        const StopDateAdjust = (event) => {
            var date = new Date(this.state.jobForm.start_job);
            //console.log(date.setDate(date.getDate() + event.target.value*7))
            this.setState({weeksDiff:date.setDate(date.getDate() + event.target.value * 7)})
            this.setState(prevState => ({jobForm:{                   // object that we want to update
                ...prevState.jobForm,    // keep all other key-value pairs
                stop_job: format(date, 'yyyy-MM-dd')      // update the value of specific key
                }
            }))
        }

        const checkEnter = (event) => {
            console.log(event.key)
        }

        const handleSubmit = async (event) => {
            event.preventDefault();
            if (this.state.submitForm === "delete") {
                let data = {
                    'hashcode_job': event.target.hashcode_job.value,
                    'client_name': this.state.jobForm.name_client,
                    'client_id': this.state.jobForm.name_client,
                }
                console.log(data)
                fetch('/api/job/delete', {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",

                    },
                    body: JSON.stringify(data),
                }).then(res => res.json()).then(
                    handleClose()
                );
            } else {
                let data = {
                    'address_hire': this.state.jobForm.address_hire,
                    'postcode_hire': null,
                    'invoice_hire': this.state.jobForm.invoice_hire,
                    'client_name': this.state.jobForm.name_client,
                    'client_id': this.state.jobForm.name_client,
                    'type_job': this.state.jobForm.type_job,
                    'start_job': this.state.jobForm.start_job,
                    'stop_job': this.state.jobForm.stop_job,
                    'finished_job': this.state.jobForm.finished_job,
                    'paid_job': this.state.jobForm.paid_job,
                    'amountpaid_job': this.state.jobForm.amountpaid_job,
                    'supervisor_hire': this.state.jobForm.supervisor_hire,
                    'inspection_hire': this.state.jobForm.inspection_hire,
                    'alarm_hire': this.state.jobForm.alarm_hire,
                    'licence_hire': this.state.jobForm.licence_hire,
                    'lights_hire': this.state.jobForm.lights_hire,
                    'sell_hire': this.state.jobForm.sell_hire,
                    'hoarding_hire': this.state.jobForm.hoarding_hire,
                    'hashcode_job': event.target.hashcode_job.value,
                    'hashcode_hire': event.target.hashcode_hire.value,
                    'clientnotes_job': this.state.jobForm.clientnotes_job,
                    'notes_job': this.state.jobForm.notes_job,
                    'price_discount': this.state.jobForm.price_discount,
                    'mail_notification': this.state.jobForm.mail_notification,
                }
                fetch('/api/job/update', {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",

                    },
                    body: JSON.stringify(data),
                }).then(res => res.json()).then(data => {
                    handleClose()
                });
            }
        };

        if (!Object.keys(this.props.clients).length || !Object.keys(this.props.addresses).length) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                <Dialog open={this.props.dlgOpen} onClose={handleClose} maxWidth={this.state.wide ? 'xl':'sm'} fullWidth={true}>
                    <Stack direction='row'>
                        <Stack>
                    <form onSubmit={handleSubmit} onKeyDown={checkEnter}>
                        <DialogTitle  style={{ backgroundColor:'#223c53' }}>
                            <Stack></Stack>
                            <Grid container columnSpacing={.5}>
                                <Grid item xs={10}>{this.state.header.title}</Grid>
                                <Grid item xs={2}>
                                    <ToggleButton fullWidth margin="dense" size="small"value="Discount" onChange={FieldChange} color="overdue" style={this.state.jobForm.mail_notification == 'True' ?{ backgroundColor:'#a90826' }:{ backgroundColor:'#223c53' }}>
                                        {this.state.jobForm.mail_notification == 'True' ? 'email off':'email on'}
                                    </ToggleButton>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                 {this.state.jobForm.address_hire}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                            <Divider/>
                        <DialogContent>
                            <Grid container alignItems="center" mb={2} columnSpacing={.5}>
                                <Grid item xs={3}>
                                    <TextField id="invoice_hire" label="Invoice number" variant="outlined" margin="dense" size="small"
                                               onChange={FieldChange} value={this.state.jobForm.invoice_hire}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={GetInvoice}><HelpIcon/></IconButton>
                                </Grid>
                                <Grid item pt={0.5} xs={8}>
                                    <Autocomplete
                                        margin="dense"
                                        size="small"
                                        freeSolo
                                        id="client_name"
                                        disableClearable
                                        onInputChange={ChangeClient}
                                        onChange={ChangeClient}
                                        value={this.state.jobForm.name_client}
                                        options={this.props.clients.map((option) => option.name_client)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Client name"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
{/* Date section  */}
                            <Grid container alignItems="center" mt={1} mb={1} columnSpacing={.5}>
                                <Grid item xs={3.2}>
                                    <TextField required size="small" margin="dense" id="start_job" label="Hire start"
                                               fullWidth type="date" variant="outlined" value={this.state.jobForm.start_job}
                                               onChange={handleHireStart} InputLabelProps={{shrink: true,}}/>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <TextField disabled={this.state.stopDate} size="small" margin="dense" variant="outlined"
                                               type="number" id="extend_by"
                                               value = {this.state.weeksDiff}
                                               label="Weeks count" onChange={StopDateAdjust}/>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <TextField required size="small" margin="dense" id="stop_job" label="Hire end"
                                               fullWidth type="date" variant="outlined" value={this.state.jobForm.stop_job}
                                               onChange={handleHireEnd} InputLabelProps={{shrink: true,}}/>
                                </Grid>
                                <Grid item xs={3.1}>
                                    <TextField size="small" margin="dense" id="finished_job" label="Hire dismantled"
                                               fullWidth type="date" variant="outlined"
                                               defaultValue={this.props.job_details.finished_job} onChange={FieldChange}
                                               InputLabelProps={{shrink: true,}}/>
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Grid container mt={2} columnSpacing={.5}>
                                <Grid item xs={9}>
                                    <Autocomplete
                                        margin="dense"
                                        size="small"
                                        freeSolo
                                        id="address_hire"
                                        disableClearable
                                        onChange={ChangeAddress}
                                        onInputChange={ChangeAddress}
                                        value={this.state.jobForm.address_hire}
                                        options={this.props.addresses.address_list === undefined ? [] : this.props.addresses.address_list}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Address"
                                                required
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        margin="dense"
                                        size="small"
                                        mt={20}
                                        freeSolo
                                        id="supervisor_hire"
                                        disableClearable
                                        value={this.state.jobForm.supervisor_hire}
                                        onChange={ChangeSupervisor}
                                        onInputChange={ChangeSupervisor}
                                        options={this.state.supervisors.map((option) => option)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Supervisor"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" mt={2}>
                                <Grid item xs={12}>
                                    <input type='hidden' id='hashcode_job' value={this.props.job_details.hashcode_job}/>
                                    <input type='hidden' id='hashcode_hire' value={this.props.job_details.hashcode_hire}/>
                                    <TextField style={{ backgroundColor:'#818181' }} margin="dense" size="small" id="type_job" label="Job description" fullWidth
                                        multiline maxRows={5} variant="outlined" onChange={FieldChange} defaultValue={this.state.jobForm.type_job}/>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" mt={2} mb={0} columnSpacing={.5}>
                                <Grid item xs={2}>
                                    <ToggleButton fullWidth margin="dense" size="small" value="Discount" onChange={FieldChange} style={this.state.jobForm.price_discount == 'True' ?{ backgroundColor:'#08a9a7' }:{ backgroundColor:'#363537' }}>Discount</ToggleButton></Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="outlined">Paid status</InputLabel>
                                        <Select
                                            margin="dense"
                                            size="small"
                                            id="Paid"
                                            value={this.state.jobForm.paid_job}
                                            label="Paid"
                                            onChange={handlePaidSelect}
                                        >
                                            <MenuItem value="Paid">Paid</MenuItem>
                                            <MenuItem value="NotPaid">Not Paid</MenuItem>
                                            <MenuItem value="Partially">Paid Partially</MenuItem>
                                            <MenuItem value="Discount">Discount</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="outlined">Paid amount</InputLabel>
                                        <OutlinedInput
                                            margin="dense"
                                            size="small"
                                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                                            id="amountpaid_job" label="Amount" fullWidth variant="outlined"
                                            onChange={FieldChange} value={this.state.jobForm.amountpaid_job}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <ToggleButton fullWidth margin="dense" size="small" value="Discount" style={this.state.wide?{ backgroundColor:'#08a9a7' }:{ backgroundColor:'#363537' }} onChange={()=>{this.setState({wide:!this.state.wide})}}>Calculation</ToggleButton></Grid>
                            </Grid>
                            <Grid container mt={2} mb={2} columnSpacing={.5}>
                                <Grid item xs={7}>
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Stack direction={'row'}>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="inspection_hire" checked={this.state.jobForm.inspection_hire}/>} label="Inspection"/>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="licence_hire" checked={this.state.jobForm.licence_hire}/>} label="Licence"/>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="alarm_hire" checked={this.state.jobForm.alarm_hire}/>} label="Alarm"/>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="hoarding_hire" checked={this.state.jobForm.hoarding_hire}/>} label="Hoarding"/>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="lights_hire" checked={this.state.jobForm.lights_hire}/>} label="Lights"/>
                                <FormControlLabel onChange={CheckboxChange} control={<Checkbox id="sell_hire" checked={this.state.jobForm.sell_hire}/>} label="Design"/>
                            </Stack>
                            <Divider/>
                            <TextField multiline maxRows={5} variant="outlined" style={{ backgroundColor:'#818181' }} margin="dense" size="small"id="notes_job" label="Notes" fullWidth onChange={FieldChange} value={this.state.jobForm.notes_job}/>
                            <TextField multiline maxRows={5} variant="outlined" style={{ backgroundColor:'#818181' }} margin="dense" size="small"id="clientnotes_job" label="Notes for client" fullWidth onChange={FieldChange} value={this.state.jobForm.clientnotes_job}/>
                        </DialogContent>
                        <DialogActions>
                            {this.props.job_details.finished_job ?
                                <Button variant="outlined" color="warning" type="submit"
                                        onClick={() => this.setState({submitForm:"delete"})}>Delete item</Button> : ""
                            }
                            <Button variant="outlined" onClick={handleClose}>Cancel Changes</Button>
                            <Button variant="outlined" color="success" type="submit"
                                    onClick={() => this.setState({submitForm:"confirm"})}>Confirm Changes</Button>
                        </DialogActions>
                    </form>
                    </Stack>
                    <Stack>
                        <Box></Box>
                    </Stack>
                    </Stack>
                </Dialog>

            </div>
        )
    }
}
export default CTRL_AddJob;