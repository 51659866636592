import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormGroup,
    Grid,
    Stack,
    Typography,
    TextField,
    MenuItem, Select, Divider
} from "@mui/material";
import CalcItem from "./CTRL_CalcItem";
import CalcItemMenu from "./CTRL_CalcItemMenu";
import React from "react";



class ScaffSegment extends React.Component{
    constructor(props) {
        super(props);
            this.state = {
                scaffSegment:{
                    'id':0,
                    "name":'',
                    'type':'',
                    'scafftype':'ID',
                    'price_modifier':0,
                    'result':"",
                    'assetlist':{},
                    'menuname':'',
                },
                assetList:{},
                sum:0
            }
        }

    calcTypeChange = (event) => {
        const scaffoldingType = this.props.itemtemplate['scafftype'].menulist.find(
            (item) => item.option === event.target.value
        );
        if (scaffoldingType) {
            this.setState(prevState => ({
                scaffSegment: {                   // object that we want to update
                    ...prevState.scaffSegment,    // keep all other key-value pairs
                    scafftype: event.target.value,       // update the value of specific key
                    menuname: scaffoldingType.name,        // update the value of specific key
                    price_modifier: scaffoldingType.price
                }
            }))
        }
    }
    componentDidMount() {


            this.setState(prevState => ({scaffSegment:{                   // object that we want to update
                ...prevState.scaffSegment,    // keep all other key-value pairs
                id: this.props.index,
                name: 'Side_'+this.props.index,
                menuname:this.props.itemtemplate.scafftype.menulist[0].name,
            }
            }))

        if (this.props.scaffSegmentLoadedData){
            this.setState({scaffSegment:this.props.scaffSegmentLoadedData})
            }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
            const fQuery = this.props.scaffquery.map((item, i)=>{
                if (i===this.props.index){
                    return this.state.calculation
                }else {
                    return item
                }
            })

        if (prevState.scaffSegment.scafftype != this.state.scaffSegment.scafftype){
        }

         if (this.state.assetList != prevState.assetList || prevState.scaffSegment.scafftype != this.state.scaffSegment.scafftype || prevState.scaffSegment.name != this.state.scaffSegment.name ) {
                let _sum=0
                for (const property in this.state.assetList) {
                        if (this.state.assetList[property].active){
                           _sum +=(this.state.assetList[property].finalprice*this.state.assetList[property].active)
                        }
                    }
                this.setState({sum: _sum})

             let _assetlist = {}
             for (const property in this.state.scaffSegment.assetlist) {
                 if (this.state.scaffSegment.assetlist[property].active===true && this.state.scaffSegment.assetlist[property].finalprice!=0) {

                     if (property==='menscaffarea' || property==='menuscaffvolume'){

                     }

                     //console.log(this.state.scaffSegment.assetlist[property])
                     _assetlist[property] = this.state.scaffSegment.assetlist[property]
                 }

             }

             this.props.fn_update({
                 'name':this.state.scaffSegment.name,
                 'type':this.state.scaffSegment.type,
                 'scafftype':this.state.scaffSegment.scafftype,
                 'id':this.state.scaffSegment.id,
                 'price_modifier':this.state.scaffSegment.price_modifier,
                 'result':this.state.scaffSegment.menuname,
                 'assetlist':_assetlist},
                 this.state.scaffSegment.id)
         }
         if (prevProps.scaffSegmentLoadedData != this.props.scaffSegmentLoadedData && this.props.scaffSegmentLoadedData.length>0){
            this.setState({scaffSegment:this.props.scaffSegmentLoadedData[this.props.index]})
         }

     }

    render() {

        const ReturnSum= ()=>{
            return(
                <TextField fullWidth multiline value={this.state.sum}/>
            )
        }


        const calcSelectChange = (value, key) => {

            this.setState({assetList: {...this.state.assetList,
                [key]:value  // update the value of specific key
            }})

            this.setState(prevState => ({scaffSegment:{                   // object that we want to update
                ...prevState.scaffSegment,    // keep all other key-value pairs
                    assetlist: {...prevState.scaffSegment.assetlist,
                        [key]:value
                }      // update the value of specific key
            }
            }))
        };

        const calcMenuSelectChange = (value, key) => {
            this.setState({assetList: {...this.state.assetList,
                [key]:value  // update the value of specific key
            }})

            this.setState(prevState => ({scaffSegment:{                   // object that we want to update
                ...prevState.scaffSegment,    // keep all other key-value pairs
                    assetlist: {...prevState.scaffSegment.assetlist,
                        [key]:value
                }      // update the value of specific key
            }
            }))
        };

        const updateScaffSegment = (event, key) => {
            this.setState(prevState => ({scaffSegment:{                   // object that we want to update
                ...prevState.scaffSegment,    // keep all other key-value pairs
                [key]: event.target.value       // update the value of specific key
            }
            }))
        };

        const menuScaffTypeSelect = () => {
                    return (
                        <Select
                            size="small"
                            value={this.state.scaffSegment.scafftype}
                            onChange={this.calcTypeChange}
                            //options={this.props.iterator['scafftype'].menulist?.map(({option, index}) => ({value:option.option, label:option.name}))}
                            fullWidth={true}
                            name='scafftype'
                            >
                            {
                            this.props.itemtemplate['scafftype'].menulist?.map((item,index)=> (
                                <MenuItem
                                    key={index}
                                    value={item.option}
                                    data-name={item.name}
                                    data-price={item.price}
                                  >
                                    {item.name}
                              </MenuItem>
                             ))
                            }
                        </Select>
                    )
        }

        const menuitemselect = (id) => {
            if (this.props.itemtemplate[id]['name']==='scafftype'){

            }
            //console.log(key, this.props.iterator[key].valid, this.state.scaffSegment.scafftype, this.props.iterator[key].calc)
            if (this.props.itemtemplate[id].visible) {
                if (this.props.itemtemplate[id].valid===undefined || this.props.itemtemplate[id].valid.includes(this.state.scaffSegment.scafftype)) {
                    return (
                        <CalcItemMenu
                            key={id}
                            id={{id}}
                            loadedAsset={this.props.scaffSegmentLoadedData.assetlist[id] || null}
                            segment={this.props.itemtemplate[id]['name']==='purpose'?this.state.scaffSegment:null}
                            vars={this.props.itemtemplate[id]}
                            fn_update={(value) => calcMenuSelectChange(value, id)}
                        />
                    )
                }else{
                    //console.log(this.state.scaffSegment.assetlist[key])

                    }
                }
            }

        const calcitemselect = (id, uid) => {
            if (this.props.itemtemplate[id].visible){
                if (this.props.itemtemplate[id].valid===undefined || this.props.itemtemplate[id].valid.includes(this.state.scaffSegment.scafftype)) {
                    return(
                       <CalcItem
                            key={id}
                            segment={this.state.scaffSegment}
                            loadedAsset={this.props.scaffSegmentLoadedData.assetlist[id] || null}
                            vars={this.props.itemtemplate[id]}
                            fn_update={(value) => calcSelectChange(value, id)}/>
                      )
            }else{
              return(
                  <Typography variant="h6" component="div">None</Typography>
              )
            }}
        }

        const handleItems = () => {
            return Object.keys(this.props.itemtemplate).map((key, value) => {
                if (['scafftype'].includes(key)){
                    return null
                }else{
                    if (key.startsWith('menu')) {
                        return menuitemselect(key)
                    } else {
                        return calcitemselect(key)
                    }
                }
            });
        }

        return(
            <FormGroup key={this.props.key}>
            <Card variant="outlined" sx={{minWidth: 350, maxWidth:450}}>
                <CardContent justify="left">
                    <Typography align='center' component='div' variant="h6" sx={{color:'primary'}}>Segment name</Typography>
                    <TextField
                        required
                        size="small"
                        margin="dense"
                        fullWidth
                        value={this.state.scaffSegment.name}
                        onChange={(event, key)=>updateScaffSegment(event, 'name')}
                    />
                    <Typography variant="h6" component="div">Scaffold type</Typography>
                    {menuScaffTypeSelect ('scafftype')}
                    <Typography variant="h6" component="div">Scaffold purpose</Typography>
                    {handleItems()}
                    <Divider/>
                    <Typography variant="h6" component="div">Result</Typography>
                    <Divider/>
                    <ReturnSum/>
                </CardContent>
            </Card>
            </FormGroup>
        );
    }
}


export default ScaffSegment;