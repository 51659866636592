import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormGroup,
    Grid,
    Stack,
    Typography,
    TextField,
    MenuItem, Select, Divider, CardHeader
} from "@mui/material";
import CalcItem from "./CTRL_CalcItem";
import CalcItemMenu from "./CTRL_CalcItemMenu";
import React from "react";
import PaidSelect from "./CTRL_PaidSelect";



class CalcSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total:0
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.priceList !== this.props.priceList) {
            const assetprices = [];
            Object.values(this.props.priceList).forEach(item => {
                Object.values(item.assetlist).forEach(asset => {
                    assetprices.push(asset.finalprice);
                });
            });

            const _total = assetprices.reduce((total, value) => total + value, 0);
            if (prevState.total !== _total) {
                this.setState({ total: _total });
            }
        }
    }

    render() {

        const innerFrame = (assetlist, id) => {
            const assetprices = [];

            Object.keys(assetlist).forEach((key) => {
                assetprices.push(assetlist[key].finalprice);
            });

            const segmentprice = assetprices.reduce((total, value) => total + value, 0);

            //this.setState({total:_total})

            const assets = Object.keys(assetlist).map((key,index) => (
                <Grid container pt={1} key={key+'f_'+index}>
                <Grid item xs={7}align='right'>
                    <Typography variant='h6'>{assetlist[key].result?assetlist[key].result:this.props.template[key].name}:</Typography>
                </Grid>
                <Grid item xs={5} align='left' pl={2}>
                    <Typography variant='h6'>{'£'+assetlist[key].finalprice}</Typography>
                </Grid></Grid>
                )
            )

            return (
            <Grid container>
                {assets}
                <Grid item xs={12} mt={1} mb={1}><Divider/></Grid>
                <Grid item xs={7} alignContent="right">
                    <Typography variant="h5" align='right'>Segment price:</Typography>
                </Grid>
                <Grid item xs={5} align = "left">
                    <Typography variant="h5" pl={1}>£ {segmentprice}</Typography>
                </Grid>
            </Grid>
          );
            }

        const selectType = (scafftype) => {
            const type = this.props.template.scafftype.menulist.find(type => type.option===scafftype)
            return(
                <div>{type}</div>
            )
        }

    const CalculationCard = () => {
        const priceElements = Object.entries(this.props.priceList).map(([key, value], index) => (
            <Card key={key + '_' + index}>
                <CardContent>
                    <Typography variant="h4">{value.name}</Typography>
                    <Divider />
                    <Typography variant="h5">type: {value.result}</Typography>
                    <Divider />
                    {innerFrame(value.assetlist, key)}
                </CardContent>
            </Card>
        ));

        return (
            <Stack spacing={1}>
                {priceElements}
                <Card>
                    <CardContent>
                        <Typography variant="h4">Total: £{this.state.total}</Typography>
                    </CardContent>
                </Card>
            </Stack>
        );
    };
        if (!this.props.priceList) {
            return <div>Loading...</div>;
        }
        return (
            <Stack spacing={1} pr={1} mt={2}>
                <Card>
                    <CardContent>
                <Typography variant='h4'>Summary</Typography>
                        </CardContent>
                </Card>
                <CalculationCard/>
                <Typography variant="subtitle2">{JSON.stringify(this.props.priceList, null, 2).split("\n")}</Typography>
            </Stack>
        )
    }
}
export default CalcSummary;