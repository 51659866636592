import {DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import {Switch, ButtonGroup, Snackbar,Alert, Divider, Autocomplete, Button, Checkbox, Select, MenuItem, Input, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, FormControlLabel,NativeSelect, Stack, TextField, DialogActions, Tooltip, Paper, Box, Typography, IconButton} from '@mui/material';
import {darken, lighten } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import React from "react";




export default function DG_JobList(props) {

function split_to_list(items) {
    if (items) {
        const name_list = items.split(";").map((item, index) =>
            <Typography key={index} align="left" variant="body1">{item}</Typography>
        )
        return (
            name_list
        )
    }else{
        const name_list = null
    }}
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const Delete = (item) => {
    console.log (item)
    var url = '/api/clients/delete/'+item.id_client;
        fetch(url).then(response => response.json()).then(data => {
            window.location.reload()
        }
    );
}

const DeleteClient = (item) =>{
    if (item.row.jobs_current_count+item.row.jobs_overdue_count+item.row.jobs_finished_count>0) {
        return(
                 <Tooltip title="To delete, client must have no jobs (click to move jobs to other client)">
                 <Button variant={'contained'} size={'small'} onClick={() =>  {props.fn_merge(item.row)}}>Delete (Merge)</Button>
                 </Tooltip>
        )
    }else{
           return(
                 <Tooltip title="No items found for this client, can be deleted">
                 <Button variant={'contained'} size={'small'} onClick={() =>  {Delete(item.row)}}>Delete Client</Button>
                 </Tooltip>
        )
    }
}


const Subscribe = (event) => {
    var url = '/api/clients/subscribe/'+event.target.id;
    fetch(url).then(response => response.json()).then(data => {
        props.recordModified(true)
    })
}


const columns: GridColDef[] = [
    {
        field: 'id_client',
        headerName: 'client id',
        flex: .008,
        editable: false,
    },
    {
        field: 'name_client',
        headerName: 'Client Name',
        minWidth: 70,
        flex: .04,
        editable: false,
    },
    {
        field: 'mailing_disabled',
        headerName: 'Mailing',
        minWidth: 5,
        flex: .008,
        editable: false,
        renderCell: (cellValues) => {
            let mess = "Subscribed to mailings";
            if (cellValues.row.mailing_disabled){
                mess="Unsubscribed from mailings"
            }
            return (
                <Tooltip  title={mess}>
                    <Switch checked={!cellValues.row.mailing_disabled}
                            id = {cellValues.row.id_client}
                            color="info"
                            onClick={Subscribe}
                            />
                 </Tooltip>
            );
        },
    },
    {
        field: 'emails_client',
        headerName: 'Emails',
        minWidth: 70,
        flex: .04,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <div>
                    {split_to_list(cellValues.row.emails_client)}
                </div>
            );
        },
    },
    {
        field: 'phones_client',
        headerName: 'Phones',
        minWidth: 70,
        flex: .04,
        editable: false,
        renderCell: (cellValues) => {
            return (
                <div>
                    {split_to_list(cellValues.row.phones_client)}
                </div>
            );
        },
    },
    {
        field: 'jobs_current_count',
        headerName: 'ongoing',
        minWidth: 70,
        flex: .01,
        editable: false,
        headerClassName: 'super-app-theme--current',
        cellClassName:'super-app-theme--current',

    },
    {
        field: 'jobs_overdue_count',
        headerName: 'overdue',
        minWidth: 70,
        flex: .01,
        editable: false,
        headerClassName: 'super-app-theme--overdue',
        cellClassName:'super-app-theme--overdue',
    },
    {
        field: 'jobs_finished_count',
        headerName: 'finished',
        minWidth: 70,
        flex: .01,
        editable: false,
        headerClassName: 'super-app-theme--finished',
        cellClassName:'super-app-theme--finished',
    },
    {
        field: 'edit',
        headerName: '',
        minWidth: 70,
        flex: .04,
        editable: false,
        renderCell: (cellValues) => {
          return (
            <Stack direction="row" justifyContent spacing={1}>
                <Tooltip title="Job info for this client">
                    <IconButton variant={'contained'} size={'small'} onClick={() => props.fn_info(cellValues)}>
                        <InfoIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit client (you can also double click on the row)">
                    <IconButton size={'small'} onClick={() => props.fn_edit(cellValues)}>
                          <ModeEditIcon/>
                    </IconButton>
                </Tooltip>
                <DeleteClient row={cellValues.row} dlgopen={props.mergeopen} setdlgopen={props.setmergeopen}/>
                <Button variant={'contained'} size={'small'} onClick={() =>  props.fn_add(cellValues)}>Add Job</Button>
            </Stack>
          );
        }
    },
];

//const classes = useStyles();

    return (
        <Box mb={20} sx={{
            textAlign: 'left',
            height: 1000,
            width: 1,
            '& .super-app-theme--finished': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.finished.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                },
            },
            '& .super-app-theme--current': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.current.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(
                            theme.palette.current.main,
                            theme.palette.mode,
                        ),
                },
            },
            '& .super-app-theme--ending': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.ending.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(
                            theme.palette.ending.main,
                            theme.palette.mode,
                        ),
                },
            },
            '& .super-app-theme--overdue': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.overdue.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(theme.palette.overdue.main, theme.palette.mode),
                },
            },
            '& .super-app-theme--waiting': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.waiting.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(theme.palette.waiting.main, theme.palette.mode),
                },
            },
            '& .super-app-theme--confirmed': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.confirmed.main, theme.palette.mode),
                '&:hover': {
                    bgcolor: (theme) =>
                        getHoverBackgroundColor(theme.palette.confirmed.main, theme.palette.mode),
                },
            },
        }}>
            <DataGrid
                //getRowId={(row) => row._id}
                onCellDoubleClick={(cellValues) => props.fn_edit(cellValues)}
                id="id_client"
                getRowClassName={(cellValues) => `super-app-theme--${cellValues.row.status}`}
                sx={{cursor: "pointer"}}
                rows={props.gridItems}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.id_client}
                components={{Toolbar: GridToolbar}}
                //className={classes.cell}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'name_client', sort: 'asc' }],
                    },
                }}
            />

        </Box>
    )
}