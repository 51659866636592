import {
    FormControl,
    FormControlLabel,
    Form,
    Select,
    MenuItem,
    ToggleButtonGroup,
    Button,
    Chip,
    Fab,
    Stack,
    Switch,
    Tooltip,
    Paper,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import {gridClasses, GridRowHeightParams, GridToolbarContainer, GridToolbarDensitySelector,} from '@mui/x-data-grid';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MapIcon from '@mui/icons-material/Map';
import CheckIcon from '@mui/icons-material/Check';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import {darken, lighten} from '@mui/material/styles';
import DLG_NavBar from "./DLG_NavBar";
import DLG_HireFilter from "./DLG_HireFilter";
import DLG_ButtonExtendHire from "./DLG_ButtonExtendHire";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DLG_Alert_General from "./DLG_Alert_General";
import DLG_AddJob from "./DLG_AddJob";
import CTRL_AddJob from "./Controls/CTRL_JobCard";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import PaidSelect from './Controls/CTRL_PaidSelect'
import JobMail from "./Controls/CTRL_JobMailSubscribe";
import HireExtend from "./Controls/CTRL_HireExtend";
import JobList from "./Controls/CTRL_JobList";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ClearSchedule from "./Controls/CTRL_ClearSchedule";

const overdueSwitch = (param) => {
    param = Math.round((param / 7) * 2) / 2;
    if (param >= 0) {
        return <Typography color='red'>{param}</Typography>
    } else if (param < 0) {
        return Math.abs(param)
    } else {
        return ""
    }
}

const warningSwitch = (param) => {
    if (Math.floor((new Date(param) - new Date()) / (1000 * 60 * 60 * 24)) < 7) {
        return <WarningAmberIcon color="error"/>
    } else {
        return ""
    }
}

const fabStyle = {
    position: 'absolute',
    bottom: 56,
    left: 36,
};


function ShowQRC(hashcode) {
    let url = window.location.host.split(":")[0]
    if (url.startsWith("192")) {
        url = "http://" + url + ":5000/qrc/" + hashcode
    } else {
        url = "http://" + url + "/qrc/" + hashcode
    }
    window.open(url);
}

function find_map(event) {
    var url = 'https://www.google.com/maps/place/' + event.target.value.replaceAll(" ", "+")
    window.open(url);
}


const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function MainHires() {

    const [addJobOpen, setAddJobOpen] = React.useState(false);
    const [hires, setHires] = React.useState([]);
    const [addresses, setAddresses] = React.useState({});
    const [oprhanedAddresses, setOrphanedAddresses] = React.useState({});
    const [clients, setClients] = React.useState([]);
    const [jobDetail, setJobDetail] = React.useState({});
    const [lastInvoice, setLastInvoice] = React.useState(null)
    const [alertOpen, setAlertOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [updateDG, setUpdateDG] = React.useState(false)
    const [rowItem, setRowItem] = React.useState({});

    const [hireExtendOpen, setHireExtendOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const [notification, setNotification] = React.useState({
        "reminder": "0", "extend_request": "0", "close_request": "0"
    })

    const columns: GridColDef[] = [
        {
            hide: true,
            field: 'hashcode_id',
        },
        {
            field: 'invoice',
            headerName: 'Invoice',
            minWidth: 130,
            editable: false,
            flex: .08,
        },
        {
            field: 'start_job',
            headerName: 'Hire Start',
            minWidth: 90,
            flex: .01,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>{cellValues.row.start_job}</div>
                );
            },
        },
        {
            field: 'overdue',
            headerName: 'Weeks overdue',
            minWidth: 40,
            flex: .04,
            editable: false,
            type: 'number',
            renderCell: (cellValues) => {
                return (
                    <div>
                        {overdueSwitch(cellValues.row.overdue)}
                    </div>
                );
            }
        },
        {
            field: 'stop_job',
            headerName: 'Hire End',
            minWidth: 140,
            flex: 0.01,
            editable: false,
            renderCell: ({row}) => {
                if (row.finished_job === 'None') {
                    return (
                        <>
                            <IconButton
                                size={'small'}
                                variant="contained"
                                color="current"
                                onClick={() => {
                                    setSelectedRow(row);
                                    setHireExtendOpen(true);
                                }}
                            >
                                <MoreTimeIcon/>
                            </IconButton>
                            <Typography>{row.stop_job}</Typography>
                        </>
                    );
                } else {
                    return <Typography>{row.stop_job}</Typography>;
                }
            },
        },

        {
            field: 'paid_job',
            headerName: 'Paid',
            minWidth: 160,
            editable: false,
            flex: .07,
            renderCell: (cellValues) => {
                if (cellValues.row.ispaid === "Partially") {
                    console.log("Partially")
                }

                return (
                    <PaidSelect fn_update={() => {
                        setUpdateDG(!updateDG)
                        setAlertMessage("Payment status updated")
                        setAlertOpen(true)
                    }} ispaid={cellValues.row}/>
                )
            },
        },
        {
            field: 'address_job',
            headerName: 'Address',
            minWidth: 180,
            editable: false,
            flex: .4,
            renderCell: (cellValues) => {
                if (cellValues.row.address_job === '') {
                    return (
                        <JobList menu='short' row={cellValues.row} fn_update={() => {
                            setUpdateDG(!updateDG)
                            setAlertMessage("Address updated")
                            setAlertOpen(true)
                        }}/>
                    );
                } else {
                    return (
                        <Typography>{cellValues.row.address_job}</Typography>
                    )
                }
            },
        },
        {
            field: 'type_job',
            headerName: 'Job notes',
            minWidth: 400,
            editable: false,
            flex: .7,
            renderCell: (cellValues) => (
                <Stack>
                    <div>
                        <Typography color="secondary">Job description:</Typography>
                        {cellValues.row.type_job}</div>
                    {cellValues.row.notes_job ?
                        <div>
                            <Typography color="secondary">Internal notes:</Typography>
                            {cellValues.row.notes_job}</div> : ""}
                    {cellValues.row.clientnotes_job ?
                        <div>
                            <div>Notes for client:</div>
                            {cellValues.row.clientnotes_job}</div> : ""}
                </Stack>
            ),
        },
        {
            field: 'warning',
            headerName: 'Warning',
            width: 80,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <JobMail stop_job={cellValues.row.stop_job}
                             id_job={cellValues.row.id_job}
                             mail_notification={cellValues.row.mail_notification}
                             finished_job={cellValues.row.finished_job}
                             fn_update={() => {
                                 setUpdateDG(!updateDG)
                                 setAlertMessage("Mailing updated")
                                 setAlertOpen(true)
                             }}
                    />
                )
            }
        },
        {
            field: 'name_client',
            headerName: 'Client',
            width: 200,
            editable: false,
            filterInputProps: {
                autoFocus: true,
            },
        },
        {
            field: 'edit',
            headerName: 'edit',
            width: 20,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <IconButton size={'small'} onClick={() => JobForm(cellValues)}><ModeEditIcon/> </IconButton>
                );
            }
        },
        {
            field: 'options',
            headerName: 'Options',
            type: 'number',
            width: 200,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack>
                        <ClearSchedule hashcode={cellValues.row.hashcode_job}
                                       type={cellValues.row.schedule_type}
                                       date={cellValues.row.schedule_date}
                                        onJobChange={JobChange}
                        />
                        <div>
                            {buttonSet(cellValues)}
                        </div>
                    </Stack>
                );
            }
        },
    ];

    const updateItem = (message) => {
        setUpdateDG(!updateDG)
        setAlertMessage()
    }

    const VISIBLE_FIELDS = ['invoice', 'start_job', 'stop_job', 'schedule_type', 'options'];

    const checkReminder = (age) => {
        return age >= 18;
    }

    React.useEffect(() => {
        fetch('/api/hires').then(res => res.json()).then(data => {
            setHires(data)
            setNotification({
                ...notification,
                "reminder": String(data.filter(row => row.schedule_type === 'reminder').length + " reminders sent"),
                'extend_request': String(data.filter(row => row.schedule_type === 'extend_request').length + " extend requests"),
                'close_request': String(data.filter(row => row.schedule_type === 'close_request').length + " close requests"),
            })
        });
        fetch('/api/clients/get').then(res => res.json()).then(data => {
            setClients(data)
        });
        fetch('/api/config/addresses').then(res => res.json()).then(data => {
            setAddresses(data)
        });

        ///dlgAlertOpen("Updated")

    }, [updateDG, addJobOpen, rowItem]);


    const ButtonMap = (props) => {
        return (
            <Tooltip title="View on map">
                <IconButton
                    size={'small'}
                    variant="contained"
                    color="current"
                    onClick={() => {
                        var url = 'https://www.google.com/maps/place/' + props.item
                        window.open(url);
                    }}
                >
                    <MapIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    const ButtonCloseJob = (props) => {
        return (
            <Tooltip title="Close job">
                <IconButton
                    size={'small'}
                    variant="contained"
                    color="current"
                    name='close_confirmation'
                    onClick={() => {
                        JobChange('close_confirmation', props.hashcode)
                    }}
                >
                    <CheckIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    const ButtonQRCode = (props) => {
        return (
            <Tooltip title="Get qr code">
                <IconButton
                    size={'small'}
                    variant="contained"
                    color="current"
                    name='close_confirmation'
                    onClick={() => {
                        ShowQRC(props.hashcode)
                    }}
                >
                    <QrCode2Icon/>
                </IconButton>
            </Tooltip>
        )
    }

    const ButtonUnsubscribed = () => {
        return (
            <Tooltip title="Client unsubscribed from mailing list. You can resubscribe form Clients panel">
                <IconButton
                    size={'small'}
                    variant="contained"
                    color="info"
                    onClick={() => {

                    }}
                >
                    <UnsubscribeIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    const ButtonSendReminder = (props) => {
        if (props.row.mailing_disabled) {
            return (<ButtonUnsubscribed/>)
        } else {
            return (
                <Tooltip title="Send reminder to client">
                    <IconButton
                        size={'small'}
                        variant="contained"
                        color="overdue"
                        onClick={() => {
                            JobChange('reminder', props.hashcode)
                        }}
                    >
                        <NotificationsActiveIcon/>
                    </IconButton>
                </Tooltip>
            )
        }
    }
    const updateRow = (updatedRow) => {
        setHires((prevHires) =>
            prevHires.map((row) =>
                row.id_job === updatedRow.id_job ? updatedRow : row
            )
        );
    };
    const ButtonReopenJob = (props) => {
        return (
            <Tooltip title="Reopen job">
                <IconButton
                    size='small'
                    variant="contained"
                    color="overdue"
                    onClick={(event) => {
                        JobChange('reopen', props.hashcode);
                    }}
                >
                    <SettingsBackupRestoreIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    const ButtonClearSchedule = (props) => {
        return (
            <Stack direction='row'>
                <Tooltip title="Clear schedule">
                    <IconButton
                        size='small'
                        variant="contained"
                        color="default"
                        onClick={() => {
                            JobChange('clear_schedule', props.hashcode);
                        }}
                    >
                        <HighlightOffIcon/>
                    </IconButton>
                </Tooltip>
                <Stack>
                    <div><a href={"/client/" + props.hashcode}>{props.type}</a></div>
                    <div>{props.date}</div>
                </Stack>
            </Stack>
        )
    }

    const buttonSet = (cellValues) => {
        switch (cellValues.row.status) {
            case "finished":
                return (
                    <div>
                        <ButtonReopenJob hashcode={cellValues.row.hashcode_job}/>
                        <ButtonMap item={cellValues.row.address_job.replaceAll(" ", "+")}/>
                    </div>
                )
            case "waiting":
                return (
                    <div>
                        {cellValues.row.mailing_disabled ?
                            <ButtonUnsubscribed/> : ""
                        }
                        <ButtonCloseJob hashcode={cellValues.row.hashcode_job}/>
                        <ButtonQRCode hashcode={cellValues.row.hashcode_job}/>
                        <ButtonMap item={cellValues.row.address_job.replaceAll(" ", "+")}/>
                    </div>
                )
            default:
                return (
                    <div>
                        {cellValues.row.has_email ?
                            <ButtonSendReminder hashcode={cellValues.row.hashcode_job} row={cellValues.row}/> : ""
                        }
                        <ButtonCloseJob hashcode={cellValues.row.hashcode_job}/>
                        <ButtonQRCode hashcode={cellValues.row.hashcode_job}/>
                        <ButtonMap item={cellValues.row.address_job.replaceAll(" ", "+")}/>
                    </div>
                )
        }
    }

    function JobChange(option, hashcode) {

        var url = '/api/job/' + option + '/' + hashcode;
        dlgAlertOpen(option)
        fetch(url).then(response => response.json()).then(data => {
            if (data) {
                setAlertMessage("")
                setAlertOpen(false)
                setUpdateDG(!updateDG)
                return (
                    <div>
                        <DLG_ButtonExtendHire/>
                    </div>)

            } else {
                setAlertMessage("")
                setAlertOpen(false)
                setUpdateDG(!updateDG)
            }
        });
    }

    const dlgAlertOpen = (message) => {
        console.log(message)
        setAlertMessage("sending " + message)
        setAlertOpen(true)
    }
    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    const JobForm = (cellValues, childState) => {
        if (cellValues == undefined) {
            setJobDetail({})
        } else {
            if (cellValues.row) {
                fetch('/api/job/details/' + cellValues.row.hashcode_job).then(res => res.json()).then(data => {
                    setJobDetail(data)
                    if (data.finished_job === "None") {
                        data.finished_job = null;
                    }
                    fetch('/api/config/addresses').then(res => res.json()).then(data => {
                        setAddresses(data)
                        setAddJobOpen(true);
                    });
                });
            }
        }
    }

    const initJob = ({
        'address_hire': "",
        'postcode_hire': "",
        'invoice_hire': 0,
        'client_name': "",
        'client_id': "",
        'type_job': "",
        'start_job': "",
        'stop_job': "",
        'finished_job': "",
        'paid_job': "NotPaid",
        'amountpaid_job': "",
        'supervisor_hire': "",
        'inspection_hire': false,
        'alarm_hire': false,
        'licence_hire': false,
        'sell_hire': false,
        'hoarding_hire': false,
        'hashcode_job': "",
        'hashcode_hire': "",
        'clientnotes_job': "",
        'notes_job': "",
    });

    const GetInvoice = () => {
        fetch('/api/config/lastinvoice').then(res => res.json()).then(data => {
            setLastInvoice(data)
            //setJobDetail(jobDetail, {...jobDetail, invoice_hire:data})
            console.log(data)
            return (data)
        });
    }
    const AddJob = () => {
        setJobDetail({jobDetail, ...initJob})
        fetch('/api/config/addresses').then(res => res.json()).then(data => {
            setAddresses(data)
            fetch('/api/config/lastinvoice').then(res => res.json()).then(data => {

                setLastInvoice(data)
                //setJobDetail(...jobDetail, {
                setAddJobOpen(true);
            });
        });
    }
    const handleEditRowsModelChange = (model) => {
        console.log("test")
        const updatedRows = hires.map((row) => {
            const isRowEdited = model.ids.includes(row.id);
            if (isRowEdited) {
                const updatedValues = model.items[row.id];
                return {...row, ...updatedValues};
            }
            return row;
        });
        setHires(updatedRows);
    };
    const handleFilterModelChange = (model) => {
        console.log('Filter model changed:', model);
        // Here you can perform additional actions based on the changed filter model
        // For example, you might want to save the filter state somewhere or trigger some other action.
    };
    return (
        <Box>
            <Box sx={{
                textAlign: 'left',
                height: 900,
                width: 1,
                '& .super-app-theme--finished': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.finished.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                    },
                },
                '& .super-app-theme--current': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.current.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(
                                theme.palette.current.main,
                                theme.palette.mode,
                            ),
                    },
                },
                '& .super-app-theme--ending': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.ending.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(
                                theme.palette.ending.main,
                                theme.palette.mode,
                            ),
                    },
                },
                '& .super-app-theme--overdue': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.overdue.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.overdue.main, theme.palette.mode),
                    },
                },
                '& .super-app-theme--waiting': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.waiting.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.waiting.main, theme.palette.mode),
                    },
                },
                '& .super-app-theme--confirmed': {
                    fontSize: "11px",
                    bgcolor: (theme) =>
                        getBackgroundColor(theme.palette.confirmed.main, theme.palette.mode),
                    '&:hover': {
                        bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.confirmed.main, theme.palette.mode),
                    },
                },
            }}>
                <DataGrid
                    onEditRowsModelChange={handleEditRowsModelChange}
                    onCellDoubleClick={(cellValues) => JobForm(cellValues)}
                    getRowClassName={(cellValues) => cellValues && cellValues.row ? `super-app-theme--${cellValues.row.status}` : ""}
                    disableDensitySelector
                    disableColumnSelector
                    disableColumnFilter={false}
                    sx={{
                        cursor: "pointer",
                        [`& .${gridClasses.cell}`]: {
                            py: '5px',
                        },
                    }}
                    rows={hires}
                    columns={columns}
                    visibleFields={VISIBLE_FIELDS}
                    disableSelectionOnClick
                    components={{Toolbar: GridToolbar}}
                    getEstimatedRowHeight={() => 200}
                    getRowHeight={() => 'auto'}
                    onFilterModelChange={(model) => handleFilterModelChange(model)}
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'invoice', sort: 'desc'}],
                        },
                    }}
                />
            </Box>
            {selectedRow && (
                <HireExtend
                    open={hireExtendOpen}
                    onClose={() => setHireExtendOpen(false)}
                    row={selectedRow}
                    stop_job={selectedRow.stop_job}
                    id_job={selectedRow.id_job}
                    onUpdateRow={updateRow}
                    fn_update={() => {
                        setUpdateDG(!updateDG);
                        setAlertMessage("Hire status updated");
                        setAlertOpen(true);
                    }}
                />
            )}
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={10}>
                <Stack direction={'row'}><DLG_HireFilter clients={clients}/>
                    <Chip sx={{mt: 3, mr: 2}} color="primary" label={notification.reminder}/>
                    <Chip sx={{mt: 3, mr: 2}} color="warning" label={notification.extend_request}/>
                    <Chip sx={{mt: 3, mr: 2}} color="error" label={notification.close_request}/>
                </Stack>
                <DLG_NavBar highlight={"Hires"}/>
                <Fab variant="extended" size="small" color="primary" aria-label="add" sx={fabStyle}
                     onClick={() => AddJob()}>
                    <AddIcon sx={{mr: 1}}/>
                    Add new Job
                </Fab>
                <CTRL_AddJob current_client={jobDetail.name_client} dlgOpen={addJobOpen}
                             dlgSetOpen={setAddJobOpen}
                             clients={clients} addresses={addresses} job_details={jobDetail}
                             fn_getinvoice={(invoice) => {
                                 GetInvoice()
                             }}/>
                <DLG_Alert_General open={alertOpen} message={alertMessage}
                                   onClose={handleCloseAlert}/>
            </Paper>
        </Box>
    );
}