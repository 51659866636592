import {InputAdornment, Divider, Autocomplete, Button, Checkbox, Select, MenuItem, OutlinedInput, Grid, Dialog, DialogTitle, DialogContent, InputLabel, FormControl, FormControlLabel,NativeSelect, Stack, TextField, DialogActions, Tooltip, Paper, Box, Typography, IconButton} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {format} from 'date-fns'
import React from "react";

export default function DLG_AddJob(props) {


    const [supervisors, setSupervisors] = React.useState(["", "Artur", "Bogdan", "Dawid", "Ewa", "Grzegorz", "Tomasz"])
    const [stopDate, setStopDate] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [initialStopDate, setInitialStopDate] = React.useState();
    const [weekAdjVisible, setWeekAdjVisible] = React.useState(false)
    const [weeksDiff, setWeeksDiff] =  React.useState(0)
    const [text, setText] = React.useState({'title':"Edit job: "})
    const [submitForm, setSubmitForm] = React.useState( "")
    const initialJobForm = {
                'address_hire':"",
                'postcode_hire':"",
                'invoice_hire':0,
                'client_name':"",
                'client_id':"",
                'type_job':"",
                'start_job':"",
                'stop_job':"",
                'finished_job':"",
                'paid_job':"NotPaid",
                'amountpaid_job':"",
                'supervisor_hire':"",
                'inspection_hire':false,
                'alarm_hire':false,
                'licence_hire':false,
                'sell_hire':false,
                'hoarding_hire':false,
                'hashcode_job':"",
                'hashcode_hire':"",
                'clientnotes_job':"",
                'notes_job':"",
            }
    const [jobForm, setJobForm] = React.useState(initialJobForm);

    const handleClose = () => {
        props.dlgSetOpen(false)
        setJobForm(initialJobForm)
    }

    const handlePaidSelect = (event: SelectChangeEvent) => {
        setJobForm({...jobForm, paid_job:event.target.value});
    };
    const CheckboxChange = (event) => {
        setJobForm({...jobForm, [event.target.id]:event.target.checked});
    }
    const FieldChange = (event) => {
        console.log(event.key)
        setJobForm({...jobForm, [event.target.id]:event.target.value});
    }
    const GetInvoice = () => {
        console.log(props.fn_getinvoice())
    }

    const ChangeAddress = (event, newValue, reason) => {
        console.log(event.target)
        if(reason!="reset") {
            setJobForm({...jobForm, address_hire: newValue})
        }
    }
    const ChangeClient = (event , newValue, reason) => {
        if(reason!="reset") {
            setJobForm({...jobForm, name_client: newValue})
        }
    }
    const ChangeSupervisor = (event, newValue, reason) => {
        if(reason!="reset") {
            setJobForm({...jobForm, supervisor_hire: newValue})
        }
    }

    const handleHireStart = (event) => {
        setJobForm({...jobForm, start_job:event.target.value});
        if (jobForm.stop_job === ""){
            setJobForm({...jobForm, stop_job:event.target.value, start_job:event.target.value});
            setInitialStopDate(event.target.value)
            setWeekAdjVisible(true)
        }
        calcWeeks()
    }

    const handleHireEnd = (event) => {
        if (event.target.value){
            //setInitialStopDate(event.target.value)
            setJobForm({...jobForm, stop_job: event.target.value})
            setWeekAdjVisible(true)
            var date = new Date(event.target.value);
            console.log(date)
            calcWeeks(setWeeksDiff((date - new Date(jobForm.start_job))/(1000 * 60 * 60 * 24*7)))
        }else{
            setWeekAdjVisible(false)
        }
    }

    const calcWeeks  = (date) => {{
            if (Number.isInteger(date)) {
                console.log(date)
            } else {
                let weeks = Math.round((new Date(jobForm.stop_job) - new Date(jobForm.start_job)) / (1000 * 60 * 60 * 24 * 7))
                console.log("dates", jobForm.stop_job, jobForm.start_job)
                setWeeksDiff(weeks)
                console.log("weeks: ", weeksDiff)
            }
        }
    }

    const StopDateAdjust = (event) => {
        var date = new Date(jobForm.start_job);
        //console.log(date.setDate(date.getDate() + event.target.value*7))
        setWeeksDiff(date.setDate(date.getDate() + event.target.value*7));
        setJobForm( {...jobForm, "stop_job":format(date,'yyyy-MM-dd')})}

    const checkEnter = (event) => {
        console.log (event.key)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitForm === "delete"){
            let data = {
                'hashcode_job': event.target.hashcode_job.value,
                'client_name': jobForm.name_client,
                'client_id': jobForm.name_client,
            }
            fetch('/api/job/delete', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",

                },
                body: JSON.stringify(data),
            }).then(res => res.json()).then(
                handleClose()
            );
        } else
        {
            let data = {
                'address_hire': jobForm.address_hire,
                'postcode_hire': jobForm.postcode_hire,
                'invoice_hire': jobForm.invoice_hire,
                'client_name': jobForm.name_client,
                'client_id': jobForm.name_client,
                'type_job': jobForm.type_job,
                'start_job': jobForm.start_job,
                'stop_job': jobForm.stop_job,
                'finished_job': jobForm.finished_job,
                'paid_job': jobForm.paid_job,
                'amountpaid_job': jobForm.amountpaid_job,
                'supervisor_hire': jobForm.supervisor_hire,
                'inspection_hire': jobForm.inspection_hire,
                'alarm_hire': jobForm.alarm_hire,
                'licence_hire': jobForm.licence_hire,
                'sell_hire': jobForm.sell_hire,
                'hoarding_hire': jobForm.hoarding_hire,
                'hashcode_job': event.target.hashcode_job.value,
                'hashcode_hire': event.target.hashcode_hire.value,
                'clientnotes_job': jobForm.clientnotes_job,
                'notes_job': jobForm.notes_job,
            }
            fetch('/api/job/update', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",

                },
                body: JSON.stringify(data),
            }).then(res => res.json()).then(data => {
                handleClose()
            });
        }
        };



        React.useEffect(() => {
                if (props.dlgOpen){
                    if (props.job_details){
                        if (props.job_details.stop_job!=""){
                            setWeekAdjVisible(true)
                            setInitialStopDate(props.job_details.stop_job)
                        }
                        setJobForm(props.job_details)
                        if (props.job_details.hashcode_job === ""){
                            setText({...text, title:"Add job: "})
                        }else{
                            setText({...text, title:"Edit job: "})
                        }
                    }else{
                        console.log("No details")
                    }
                    setWeeksDiff(Math.round((new Date(props.job_details.stop_job) - new Date(props.job_details.start_job)) / 604800000))

                }

        }, [props.dlgOpen, props.job_details.hashcode_job]);
    return(
        <div>
            <Dialog open={props.dlgOpen} onClose={handleClose}>
                <form onSubmit={handleSubmit} onKeyDown={checkEnter}>
                    <DialogTitle>{text.title} {jobForm.address_hire}</DialogTitle>
                    <DialogContent>
                          <Divider/>
                            <Grid container mt={2}mb={2} columnSpacing={.5}>
                                <Grid item xs={3} >
                                    <TextField  id="invoice_hire" label="Invoice number" fullWidth variant="standard" onChange={FieldChange} value={jobForm.invoice_hire}  />
                                </Grid>
                                <Grid item xs={1} >
                                    <IconButton onClick={GetInvoice}><HelpIcon/></IconButton>
                                </Grid>
                                <Grid item xs={8} >
                                    <Autocomplete
                                        mt={20}
                                        freeSolo
                                        id="client_name"
                                        disableClearable
                                        onInputChange = {ChangeClient}
                                        onChange = {ChangeClient}
                                        defaultValue = {props.current_client}
                                        options={props.clients.map((option) => option.name_client)}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            label="Client name"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: 'search',
                                            }}
                                          />
                                        )}
                                      />
                                </Grid>
                            </Grid>
                          <Divider/>
                            <Grid container mt={1} mb={2} columnSpacing={.5}>
                                <Grid item xs={3.2}>
                                    <TextField required size="normal" margin="dense" id="start_job" label="Hire start" fullWidth type="date" variant="outlined" value={jobForm.start_job} onChange={handleHireStart} InputLabelProps={{ shrink: true, }} />
                                </Grid>
                                <Grid item xs={2.5} mt={1}>
                                    <TextField disabled = {!weekAdjVisible} size="small" margin="dense" variant="outlined" type="number" id="extend_by" defaultValue={Math.round((new Date(props.job_details.stop_job) - new Date(props.job_details.start_job)) / 604800000)} label="Weeks count" onChange={StopDateAdjust}/>
                                </Grid>
                                <Grid item xs={3.2}>
                                    <TextField required size="normal" margin="dense" id="stop_job" label="Hire end" fullWidth type="date" variant="outlined" value={jobForm.stop_job} onChange={handleHireEnd} InputLabelProps={{ shrink: true, }} />
                                </Grid>
                                <Grid item xs={3.1}>
                                     <TextField size="normal" margin="dense" id="finished_job" label="Hire dismantled" fullWidth type="date" variant="outlined" defaultValue={props.job_details.finished_job} onChange={FieldChange} InputLabelProps={{shrink: true,}} />
                                </Grid>
                            </Grid>
                          <Divider/>
                            <Grid container mt={2}>
                                <Grid item xs={8.5} mr={2}>
                                <Autocomplete
                                    freeSolo
                                    id="address_hire"
                                    disableClearable
                                    onChange = {ChangeAddress}
                                    onInputChange = {ChangeAddress}
                                    defaultValue={props.job_details.address_hire}
                                    options={props.addresses.address_list === undefined ? []:props.addresses.address_list}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Address"
                                        required
                                        InputProps={{
                                          ...params.InputProps,
                                          type: 'search',
                                        }}
                                      />
                                    )}
                                />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="postcode_hire" label="Postcode" variant="outlined" onChange={FieldChange} defaultValue={props.job_details.postcode_hire}   />
                                </Grid>
                            </Grid>

                            <Grid container mt={2}>
                                <Grid item xs={12}>
                                    <input type='hidden' id='hashcode_job' value={props.job_details.hashcode_job}/>
                                    <input type='hidden' id='hashcode_hire' value={props.job_details.hashcode_hire}/>
                                    <TextField multiline id="type_job" label="Job description" fullWidth variant="standard" onChange={FieldChange} defaultValue={jobForm.type_job}  />
                                </Grid>
                            </Grid>
                            <Grid container mt={2} mb={2} columnSpacing={.5}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                    <InputLabel variant="outlined">Paid status</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="Paid"
                                      value={jobForm.paid_job}
                                      label="Paid"
                                      onChange={handlePaidSelect}
                                    >
                                      <MenuItem value="Paid">Paid</MenuItem>
                                      <MenuItem value="NotPaid">Not Paid</MenuItem>
                                      <MenuItem value="Partially">Paid Partially</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                    <InputLabel variant="outlined">Paid amount</InputLabel>
                                    <OutlinedInput startAdornment={<InputAdornment position="start">£</InputAdornment>} id="amountpaid_job" label="Amount" fullWidth variant="outlined" onChange={FieldChange} value={jobForm.amountpaid_job}  />
                                </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} mb={2} columnSpacing={.5}>
                                <Grid item xs={5}>
                                    <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "inspection_hire" checked={jobForm.inspection_hire} />} label = "Discount applied"/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                            mt={20}
                                            freeSolo
                                            id="supervisor_hire"
                                            disableClearable
                                            defaultValue = {props.job_details.supervisor_hire}
                                            onChange = {ChangeSupervisor}
                                            onInputChange = {ChangeSupervisor}
                                            options={supervisors.map((option) => option)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                              required
                                                label="Supervisor"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  type: 'search',
                                                }}
                                              />
                                            )}
                                          />
                                </Grid>
                            </Grid>
                          <Divider/>
                        <Stack direction={'row'}>
                        <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "inspection_hire" checked={jobForm.inspection_hire} />} label = "Inspection"/>
                        <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "licence_hire" checked={jobForm.licence_hire} />} label = "Licence"/>
                        <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "alarm_hire" checked={jobForm.alarm_hire} />} label = "Alarm"/>
                        <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "hoarding_hire" checked={jobForm.hoarding_hire} />} label = "Hoarding"/>
                        <FormControlLabel onChange={CheckboxChange} control={<Checkbox id = "sell_hire" checked={jobForm.sell_hire} />} label = "Design"/>
                        </Stack>
                          <Divider/>
                        <TextField id="notes_job" label="Notes" fullWidth variant="standard" onChange={FieldChange} value={jobForm.notes_job} />
                        <TextField id="clientnotes_job" label="Notes for client" fullWidth variant="standard" onChange={FieldChange} value={jobForm.clientnotes_job}  />
                       </DialogContent>
                    <DialogActions>
                        {props.job_details.finished_job?
                        <Button variant="outlined" color="warning" type="submit" onClick={() => setSubmitForm("delete")}>Delete item</Button>:""
                        }
                        <Button variant="outlined" onClick={handleClose}>Cancel Changes</Button>
                        <Button variant="outlined" color="success" type="submit" onClick={() => setSubmitForm("confirm")}>Confirm Changes</Button>
                    </DialogActions>
                </form>
            </Dialog>

        </div>
    )
}
