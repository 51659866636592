import {Button, Card, CardActions, CardContent, FormGroup, Grid, Stack, Typography} from "@mui/material";
import React from "react";

class CalcReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scaffquery:[]
        };

    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.props.calculation(this.state.calculation)
        if (prevState.scaffquery != this.state.scaffquery) {
            //this.setState({scaffquery:this.props.scaffquery})
            console.log(this.props.scaffquery)
        }
        //
    }

    render() {

  const scaffItem = (index) => {
            //console.log(this.state.scaffquery)
  }
        return (
            <div>
                {this.props.scaffquery.map((item, index) => {
                    return (
                        <Stack width="100%" key={index}>

                        </Stack>)
                })}
            </div>
        )
    }
}



export default CalcReport;