import {
    InputAdornment,
    Divider,
    ToggleButton,
    Switch,
    Autocomplete,
    Button,
    Checkbox,
    Select,
    MenuItem,
    OutlinedInput,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    FormControl,
    FormControlLabel,
    NativeSelect,
    Stack,
    TextField,
    DialogActions,
    Tooltip,
    Paper,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {format} from 'date-fns'
import React from "react";
import {DataGrid, gridClasses, GridColDef, GridToolbar} from "@mui/x-data-grid";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {darken, lighten} from "@mui/material/styles";
import {createDataGridStyles} from "../STL_DataGrid";

class LogDataGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const caseEmail = (data) => {
            if (data.status_event) {
                return (
                    <ContactMailIcon color="warning"/>
                )
            } else {
                if (data.status_event === null) {
                    return (
                        <ContactMailIcon color="info"/>
                    )
                } else {
                    return (
                        <ContactMailIcon color="error"/>
                    )
                }
            }
        }


        const selIcon = (data) => {
            switch (data.name_event.toLowerCase()) {
                case ("job delete"):
                    return ("")
                    break
                case ("job update"):
                    return ("")
                    break
                case ("client email"):
                    return (caseEmail(data))
                    break
                default:
                    return ("")
            }
        }

        const columns: GridColDef[] = [
            {
                hide: true,
                field: 'id_event',
                headerName: 'client id',
                minWidth: 70,
                flex: .04,
                editable: false,
            },
            {
                field: 'end_event',
                headerName: 'date',
                minWidth: 70,
                flex: .04,
                editable: false,
            },
            {
                field: 'invoice',
                headerName: 'Invoice',
                minWidth: 70,
                flex: .03,
                editable: false,
            },
            {
                field: 'client',
                headerName: 'Client name',
                minWidth: 70,
                flex: .04,
                editable: false,
            },
            {
                field: 'name_event',
                headerName: 'event type',
                minWidth: 30,
                flex: .02,
                editable: false,
                renderCell: (cellValues) => {
                    return (
                        <Grid container>
                            <Grid item xs={2}>{selIcon(cellValues.row)}</Grid>
                            <Grid item xs={10}>{cellValues.row.name_event}</Grid>
                        </Grid>
                    );
                }
            },
            {
                field: 'summary_event',
                headerName: 'summary',
                minWidth: 70,
                flex: .1,
                editable: false,
            },
            {
                field: 'comment_event',
                headerName: 'comment',
                minWidth: 70,
                flex: .1,
                editable: false,
            },
            {
                field: 'error_event',
                headerName: 'error',
                minWidth: 70,
                flex: .04,
                editable: false,
            },
        ]


        const getBackgroundColor = (mainColor, mode) => {
            // An example implementation:
            return mode === 'dark' ? darken(mainColor, 0.0) : lighten(mainColor, 0.3);
        };

        const getHoverBackgroundColor = (mainColor, mode) => {
            // An example implementation:
            return mode === 'dark' ? darken(mainColor, 0.3) : lighten(mainColor, 0.3);
        };

        if (!this.props.data) {
            return <div>Loading...</div>;
        }

        const styles = createDataGridStyles(getBackgroundColor, getHoverBackgroundColor);

        return (
            <Box
                style={{ height: this.props.gridHeight - 100, width: "100%" }}
                sx={styles}
            >
                <DataGrid
                    getRowClassName={(params) => params.row.status_event === false ? "error-row" : `dg--${params.row.style}`}
                    getRowId={(row) => row.id_event}
                    sx={{cursor: "pointer", height: "100%"}}
                    rows={this.props.data}
                    columns={columns}
                    initialState={{
                        sorting: {sortModel: [{field: "id_event", sort: "desc"}]}
                    }}
                />
            </Box>
        )
    }
}

export default LogDataGrid;