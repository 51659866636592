import {Autocomplete, Button, Grid, Box, TextField, IconButton} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import React from "react";

class QuoteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotelist: [],
            name: null,
            id_quote: null,
            id_hire: null,
            id_client: null,
        };
    }


    componentDidMount() {
        fetch('/api/quotation/getquotesinfo')
            .then((res) => res.json())
            .then((data) => {
                this.setState((prevState) => ({
                ...prevState,
                quotelist: data,
                }));
                // Set the initial state of selectedClient based on the prop
                //if (this.props.selectedClient) {
                //this.setState({ selectedClient: this.props.selectedClient });
                //}
        });
    }


    render() {

        const updateQuoteControl= (event, quotedetails, reason) => {
            if (reason !== 'reset' && quotedetails) {
                this.setState({ id_client: quotedetails.id_client });
                this.props.fn_update({
                    name: quotedetails.name,
                    id_quote: quotedetails.id_quote,
                    id_client: quotedetails.id_client,
                    id_hire: quotedetails.id_hire,
                });
            }
        }


        if (!this.state.quotelist) {
            return <div>Loading...</div>;
        }

        return (
            <Box width="100%">
                <Grid container>
                    <Grid item xs={11}>
                        <Autocomplete
                            freeSolo
                            id="quote_name"
                            aria-required
                            disableClearable
                            onChange={(event, newValue, reason, details) =>
                                updateQuoteControl(event, newValue, reason, details)
                            }
                            defaultValue=""
                            getOptionLabel={(option) => (option ? Object.values(option)[0] : "")}
                            options={this.state.quotelist}
                            renderOption={(props, option, {selected}) => {
                                // const isSpecial = option.startsWith("4");
                                const isSpecial = false;
                                const address = Object.values(option)[0];
                                return (
                                    <li {...props}
                                        key={Object.keys(option)[0]}
                                        style={{
                                            color: isSpecial ? "red" : "inherit",
                                            backgroundColor: isSpecial ? "lightgreen" : "inherit"
                                        }}>
                                        {address}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Select job"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Box>
        )
    }
}
export default QuoteList;