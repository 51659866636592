import logo from './logo.svg';
import React from 'react';
import ReactDOM from "react-dom";
import {BrowserRouter, Routes, Route, Outlet, useParams} from "react-router-dom";
import './App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import axios from "axios";
import { Navigate } from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import MainHires from "./components/MainHiresDG";
import MainConfig from "./components/MainConfig";
import MainClients from "./components/MainClientsDG";
import MainSchedule from "./components/MainSchedule";
import Reminder from "./components/Reminder";
import ClientShow from "./components/ClientShow";
import MainLogs from "./components/MainLogs";
import MainCalc from "./components/MainCalc";
import Login from './components/Login'
import useToken from './components/useToken'
import {createCustomTheme} from "./STL_Theme";

const theme = createCustomTheme


export default function App() {
    const {token, removeToken, setToken} = useToken();
    const go = (event, c) => {
        event.preventDefault();
        //window.location.assign('/'+window.location.pathname.split('/')[1]);
        window.location.assign('/manage/hires');
    };
    const [ipAddress, setIpAddress] = React.useState('');
    const [bgroundColor, setBackgroundColor] = React.useState('white');

        React.useEffect(() => {

        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        if (isLocalhost) {
            setBackgroundColor('#222');
        } else {
            setBackgroundColor('background.default');

        }
    }, []);
    function logMeOut() {
        removeToken()
        axios({
            method: "POST",
            url: "http://localhost:8000/logout",
        })
            .then((response) => {
            }).catch((error) => {
            if (error.response) {
                console.log(error.response)
                console.log(error.response.status)
                console.log(error.response.headers)
            }
        })
    }

    const Layout = () => {
        return (
            <Box>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            ATD Hire Manager
                        </Typography>
                        <Button color="inherit" onClick={go}>HOME</Button>
                        <button onClick={logMeOut}>
                            Logout
                        </button>
                    </Toolbar>
                </AppBar>
                <Outlet/>
            </Box>
        )
    }
    const get_role = (role) => {
        switch (role) {
            case'driver':
                break;
            default:
        }
    }

    const ElLogin = <Login setToken={setToken}/>


    return (
        <ThemeProvider theme={theme}>
            <Container
                className="App App-header"
                maxWidth={false}
                sx={{
                    minHeight: "100vh",
                    backgroundColor: bgroundColor,
                }}
            >
                <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <BrowserRouter basename="/">

                        {!token && token !== "" && token !== undefined ?
                            <Routes>
                                <Route path="/client" element={<Reminder/>}>
                                    <Route path=":userID" element={<ClientShow/>}/>
                                </Route>
                                <Route path="*" element={ElLogin}/>
                            </Routes>
                            : (
                                <Routes>
                                    <Route path="/" element={<Navigate to="/manage/hires"/>}/>
                                    <Route path={process.env.PUBLIC_URL+"/manage"} element={<Layout/>}>
                                        <Route path="hires" element={<MainHires/>}/>
                                        <Route path="clients" element={<MainClients/>}/>
                                        <Route path="schedule" element={<MainSchedule/>}/>
                                        <Route path="logs" element={<MainLogs/>}/>
                                        <Route path="calc" element={<MainCalc/>}/>
                                        <Route path="setup" element={<MainConfig/>}/>
                                    </Route>
                                    <Route path="/client" element={<Reminder/>}>
                                        <Route path=":userID" element={<ClientShow/>}/>
                                    </Route>
                                </Routes>)}
                    </BrowserRouter>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
